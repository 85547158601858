import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import 'Styles/RoundedRectangleButton.scss';

function RoundedRectangleButton({ icon, label, onClick, className, disabled = false }) {
  return (
    <button className={className + ' rounded_rectangle_button'} onClick={onClick} disabled={disabled}>
      {label && <div className="rounded_rectangle_button-label">{label}</div>}
      <Icon icon={icon} />
    </button>
  );
}

export default memo(RoundedRectangleButton);
RoundedRectangleButton.propTypes = {
  icon: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};
