import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';

const GoogleMapsContext = createContext();

export const useGoogleMapsContext = () => useContext(GoogleMapsContext);

export const GoogleMapsProvider = ({ isLoaded, children }) => (
  <GoogleMapsContext.Provider value={isLoaded}>{children}</GoogleMapsContext.Provider>
);

GoogleMapsProvider.propTypes = {
  isLoaded: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};
