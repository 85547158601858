import {
  DELIVERY_MAP,
  STATUS_ORDER_DELIVERY,
  STATUS_ORDER_DELIVERY_PROVIDERS,
  STATUS_ORDER_TAKE_AWAY,
  STATUS_ORDER_IN_RESTAURANT,
} from './constants';

export const getStatusList = (deliveryMethod, deliveryProvider) => {
  if (deliveryMethod === DELIVERY_MAP.delivery.key && deliveryProvider) {
    return STATUS_ORDER_DELIVERY_PROVIDERS;
  } else if (deliveryMethod === DELIVERY_MAP.delivery.key) {
    return STATUS_ORDER_DELIVERY;
  } else if (deliveryMethod === DELIVERY_MAP.takeAway.key) {
    return STATUS_ORDER_TAKE_AWAY;
  } else if (deliveryMethod === DELIVERY_MAP.inRestaurant.key) {
    return STATUS_ORDER_IN_RESTAURANT;
  }

  return [];
};
