import React, { memo } from 'react';
import PropTypes from 'prop-types';
import 'Styles/CheckBox.scss';

function CheckBox({ type = 'checkbox', id, name, isChecked, onChange, disabled = false }) {
  return (
    <label className="b-contain">
      <input
        id={id}
        name={name}
        type={type}
        checked={isChecked}
        disabled={disabled}
        onChange={() => {
          onChange(!isChecked);
        }}
      />
      <div className="b-input"></div>
    </label>
  );
}

export default memo(CheckBox);

CheckBox.propTypes = {
  id: PropTypes.string,
  isChecked: PropTypes.bool,
  name: PropTypes.string,
  type: PropTypes.oneOf(['checkbox', 'radio']),
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

