import React, { memo } from 'react';
import PropTypes from 'prop-types';
import 'Styles/StringInput.scss';

function StringInput({
  title,
  placeHolder,
  type,
  value,
  error = false,
  name,
  onValueChange,
  allowNumbers = false,
  maxLength = 50,
  disabled = false,
}) {
  return (
    <div className="string_input-container">
      {title && <span className="string_input-title">{title}</span>}
      <input
        disabled={disabled}
        className={'string_input' + (error ? ' on-error' : '')}
        type={type}
        value={value}
        name={name}
        placeholder={placeHolder}
        maxLength={maxLength}
        onChange={(event) => {
          if (type === 'number') event.target.value = event.target.value.replace(/\+|-/g, '');
          else if (type === 'text' && !allowNumbers) event.target.value = event.target.value.replace(/\d/g, '');
          onValueChange(event.target.value);
        }}
      />
    </div>
  );
}

export default memo(StringInput);

StringInput.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
  placeHolder: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.bool,
  type: PropTypes.oneOf(['number', 'text', 'tel']),
  allowNumbers: PropTypes.bool,
  onValueChange: PropTypes.func.isRequired,
  maxLength: PropTypes.number,
  disabled: PropTypes.bool,
};
