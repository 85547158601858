import { useElements, useStripe } from '@stripe/react-stripe-js';
import PropTypes from 'prop-types';
import { useEffect } from 'react';

export const StripePaymentElement = ({ onStripe, onElements }) => {
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    onStripe(stripe);
  }, [stripe]);

  useEffect(() => {
    onElements(elements);
  }, [elements]);

  return null;
};

StripePaymentElement.propTypes = {
  onStripe: PropTypes.func.isRequired,
  onElements: PropTypes.func.isRequired,
};
