import React from 'react';
import { StripeCard } from './stripe/StripeCard';
import PropTypes from 'prop-types';
import { MercadoPagoCard } from './mercadopago/MercadoPagoCard';
import { ClipCard } from './clip/ClipCard';

export const PaymentProvider = (props) => {
  if (!props?.providerCode) {
    return <h1>Provider code not found</h1>;
  } else if (props.providerCode === 'stripe') {
    return <StripeCard {...props} />;
  } else if (props.providerCode === 'mercadoPago') {
    return <MercadoPagoCard {...props} />;
  } else if (props.providerCode === 'clip') {
    return <ClipCard {...props} />;
  }

  return <h1>Payment provider UI not found</h1>;
};

PaymentProvider.propTypes = {
  total: PropTypes.number.isRequired,
  paymentProviderKey: PropTypes.string.isRequired,
  commerceId: PropTypes.string.isRequired,
  customerPhone: PropTypes.string.isRequired,
  customerOnlyPhone: PropTypes.string.isRequired,
  onStripe: PropTypes.func.isRequired,
  onStripeElements: PropTypes.func.isRequired,
  setIsReady: PropTypes.func.isRequired,
  providerCode: PropTypes.string.isRequired,
  createOrder: PropTypes.func.isRequired,
};
