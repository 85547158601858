import React, { memo } from 'react';
import PropTypes from 'prop-types';
import ListBox from './ListBox';
import ApiServices from 'ApiServices/ApiServices';
import { formatCurrency } from '../functions/money';

function Delivery({
  total,
  options,
  deliveryMethod,
  deliveryPrice,
  onValueChange,
  commerceCountryCode,
  commercePhone,
  commerceName,
  compra_minima_envio_domicilio,
}) {
  const content = [];
  if (options.delivery)
    content.push({
      id: 'delivery',
      label: 'Envío a Domicilio' + (options.freeDelivery ? ` (gratis a partir de $${options.freeDeliveryAmount})` : ''),
      count: deliveryMethod === 'delivery' ? 1 : 0,
      price: options.freeDelivery && total >= options.freeDeliveryAmount ? 0 : deliveryPrice,
      isDisabled: compra_minima_envio_domicilio && total < compra_minima_envio_domicilio,
    });
  if (options.takeAway)
    content.push({ id: 'takeAway', label: 'Pasar a Recoger', count: deliveryMethod === 'takeAway' ? 1 : 0 });
  if (options.inRestaurant)
    content.push({
      id: 'inRestaurant',
      label: 'Comer en Restaurante',
      count: deliveryMethod === 'inRestaurant' ? 1 : 0,
    });

  return content.length === 0 ? (
    <p style={{ color: 'red', textAlign: 'center' }}>
      Este comercio no tiene métodos de envío disponibles, por favor comunícate con ellos.
      <span
        style={{ fontWeight: 700, display: 'block', marginTop: '5px', cursor: 'pointer' }}
        onClick={() => {
          ApiServices.sendWhatsappMessage(
            commerceCountryCode,
            commercePhone,
            `Hola ${commerceName} tengo un problema para realizar mi pedido a través de Plick, me podrían ayudar?`,
          );
        }}
      >
        Da clic aquí para contactarlos
      </span>
    </p>
  ) : (
    <>
      <ListBox
        title="Tipo de Entrega"
        type="radio"
        content={content}
        maxValues={1}
        onLineChange={(lines) => {
          let selected = lines.find((line) => line.count > 0);
          if (selected) onValueChange(selected.id);
        }}
      />
      {deliveryMethod === 'delivery' && compra_minima_envio_domicilio && total < compra_minima_envio_domicilio ? (
        <p
          style={{
            color: 'red',
            textAlign: 'center',
            backgroundColor: '#e5ecee',
            padding: '10px',
            marginTop: '-20px',
            borderRadius: '8px',
          }}
        >
          Orden mínima de {formatCurrency(compra_minima_envio_domicilio)} para envío a domicilio, por favor agrega más
          productos a tu orden o cambia el método de entrega.
        </p>
      ) : null}
    </>
  );
}

export default memo(Delivery);

Delivery.propTypes = {
  total: PropTypes.number,
  deliveryMethod: PropTypes.string.isRequired,
  onValueChange: PropTypes.func.isRequired,
  deliveryPrice: PropTypes.number,
  commercePhone: PropTypes.number.isRequired,
  commerceCountryCode: PropTypes.number.isRequired,
  commerceName: PropTypes.string.isRequired,
  options: PropTypes.exact({
    delivery: PropTypes.bool,
    takeAway: PropTypes.bool,
    inRestaurant: PropTypes.bool,
    freeDelivery: PropTypes.bool,
    freeDeliveryAmount: PropTypes.number,
  }).isRequired,
  compra_minima_envio_domicilio: PropTypes.number || null,
};
