import * as Sentry from '@sentry/browser';

export const copyToClipboard = async (text: string) => {
  try {
    await navigator.clipboard.writeText(text);
  } catch (err) {
    try {
      const result = await navigator.permissions.query({ name: 'clipboard-write' as PermissionName });
      if (result.state !== 'denied') {
        await navigator.clipboard.writeText(text);
      }
    } catch (error) {
      Sentry.captureException(error, {
        tags: {
          action: 'copyToClipboard',
        },
      });
      throw new Error('Failed to copy to clipboard');
    }
  }
};
