import React, { useState, useCallback, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import 'Styles/Select.scss';

function Select({ currentIndex, placeHolder, label, onChange, items, error = false }) {
  const [open, setOpen] = useState(false);
  const currentValue = currentIndex >= 0 ? items[currentIndex] : '';
  const [filter, setFilter] = useState('');
  const inputRef = useRef(null);
  const listRef = useRef(null);

  const handleClose = useCallback(() => {
    if (listRef && listRef.current) {
      listRef.current.classList.add('close');
      listRef.current.addEventListener('animationend', () => {
        setFilter('');
        setOpen(false);
      });
    }
  }, []);

  useEffect(() => {
    if (open && inputRef && inputRef.current) {
      if (currentValue) inputRef.current.value = currentValue;
      inputRef.current.focus();
    }
  }, [open, currentValue]);

  const handleOpen = useCallback(() => {
    if (currentValue) setFilter(currentValue.toLowerCase());
    setOpen(true);
  }, [setOpen, setFilter, currentValue]);

  let filteredItems = filter ? items.filter((item) => item.toLowerCase().includes(filter)) : items;
  return (
    <div className={'select' + (open ? ' open' : '') + (error ? ' on-error' : '')}>
      {open ? (
        <>
          <input
            className="select-input"
            ref={inputRef}
            placeholder={label}
            onChange={(evnt) => setFilter(evnt.target.value.toLowerCase())}
          ></input>
          <div className="select-items" ref={listRef}>
            {filteredItems.map((item, idx) => (
              <div
                className={'select-item' + (currentIndex === items.indexOf(item) ? ' current' : '')}
                key={idx}
                onClick={() => {
                  handleClose();
                  onChange(items.indexOf(item));
                }}
              >
                {item}
              </div>
            ))}
          </div>
        </>
      ) : (
        <div className="select-header" onClick={handleOpen}>
          <div className="select-label">{currentValue || placeHolder}</div>
          <Icon icon={'uil:angle-' + (open ? 'up' : 'down')} />
        </div>
      )}
    </div>
  );
}

export default Select;

Select.propTypes = {
  placeHolder: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  error: PropTypes.bool,
  currentIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
};
