/* global fbq */
/* global PUBLIC_API_URL */
import './App.scss';
import React, { useEffect, useRef, useState } from 'react';
import { useJsApiLoader } from '@react-google-maps/api';
import ApiServices from 'ApiServices/ApiServices';
import PageController from 'Controllers/PageController';
import EventsManager from 'Controllers/EventsManager';
import CartController from 'Controllers/CartController';
import HomePage from 'Pages/HomePage';
import CartPage from 'Pages/CartPage';
import DeliveryPage from 'Pages/DeliveryPage';
import ProductPage from 'Pages/ProductPage';
import InfoPage from 'Pages/InfoPage';
import logo from 'Images/logo_with_slogan.svg';
import Page404 from 'Pages/Page404';
import Notification from 'Elements/Notification';
import NotificationPage from 'Pages/NotificationPage';
import PersonalDataPage from 'Pages/PersonalDataPage';
import MapPage from 'Pages/MapPage';
import OrderStatusPage from 'Pages/OrderStatusPage';
import OrderPage from 'Pages/OrderPage';
import { useWebSocket } from './hooks/hooks';
import OnlinePaymentPage from './pages/OnlinePaymentPage';
import OrderFailedPage from './pages/OrderFailedPage';
import { GoogleMapsProvider } from './context/GoogleMapsContext';
import { appCache } from './utils/cache';
import { DELIVERY_TYPES_MAP } from './utils/constants';
import useTracking, { TRACK_EVENT_NAMES } from './hooks/useTracking';
import Theme from './utils/Theme';
import * as Sentry from '@sentry/browser';
import { copyToClipboard } from './utils/clipboard';

const libraries = ['places'];

const injectUseberryScript = (idPath) => {
  if (idPath === 'fuegoextremodemo') {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://api.useberry.com/integrations/liveUrl/scripts/useberryScript.js';
    script.async = true;
    document.body.appendChild(script);
  }
};

function App() {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyAqALNE2T4Oqwz3qUT4DQyBtpGHzTUyoes',
    libraries: libraries,
  });

  const [activeOrders, setActiveOrders] = useState([]);
  const phoneNumber = appCache.phoneNumber;
  const [, setCountToRefresh] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isNotFound, setNotFound] = useState(false);
  const [origin, setOrigin] = useState('');
  const [urlVariable, setUrlVariable] = useState('');
  const [commerceData, setCommerceData] = useState({
    id: '',
    id_path: '',
    planId: '',
    name: '',
    isOpen: false,
    availablePayMethods: {
      takeAway: {},
      inRestaurant: {},
      delivery: {},
    },
    geolocalizacion: null,
    config_form_entrega: null,
    availableDeliveryMethods: {
      delivery: true,
      takeAway: true,
      inRestaurant: true,
      freeDelivery: false,
      freeDeliveryAmount: 0,
    },
    addTipsInput: false,
    deliveryPrice: 0,
    informacion_envio: null,
    deliveryAreas: [],
    deliveryType: '',
    logo: '',
    mapsUrl: '',
    address: '',
    schedule: [],
    categories: [],
    socialNetworks: {},
    countryCode: null,
    phone: null,
    deliveryPercentageQty: null,
    bankingInformation: {
      name: '',
      bank: '',
      clabe: '',
    },
    compra_minima_envio_domicilio: null,
    paymentProviderKey: null,
  });
  useWebSocket(commerceData.id, phoneNumber);
  const { setTrackingCommerce, trackEvent } = useTracking();

  const loadingPageRef = useRef(null);
  const homePageState = {
    id: commerceData.id,
    planId: commerceData.planId,
    name: commerceData.name,
    logo: ApiServices.getImagePath(commerceData.logo),
    isOpen: commerceData.isOpen,
    schedule: commerceData.schedule,
    categories: commerceData.categories,
    activeOrders: activeOrders,
  };

  const [extraData, setExtraData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await ApiServices.getExtraByCommerce(commerceData.id);
        setExtraData(res);
      } catch (error) {
        Sentry.captureException(error, {
          tags: {
            action: 'fetchingExtraData',
          },
        });
      }
    };

    if (commerceData.id) {
      fetchData();
    }
  }, [commerceData.id]);

  useEffect(() => {
    if (commerceData.id_path) {
      setTrackingCommerce(commerceData.id_path);
      injectUseberryScript(commerceData.id_path);
    }
  }, [commerceData.id_path]);

  useEffect(() => {
    if (!commerceData.id || !phoneNumber) return;

    fetch(
      `${PUBLIC_API_URL}/v1/orders?id_comercio=${commerceData.id}&numero_celular=${encodeURIComponent(
        phoneNumber,
      )}&orders_in_process=true`,
    )
      .then((response) => response.json())
      .then((data) => {
        if (data?.rows) {
          const orders = data.rows.map((order) => {
            return {
              id: order.id,
              orderId: order.folio,
              status: order.estatus,
              deliveryMethod: order.metodo_entrega,
              deliveryType: order?.delivery_type,
              hour: order.created_at,
              deliveryDriverInfo: order?.delivery_driver_info,
              deliveryTrackingUrl: order?.delivery_tracking_url,
              paymentMethod: order?.metodo_pago,
              orderTotal: order?.total,
            };
          });
          if (orders && orders.length > 0) {
            trackEvent(TRACK_EVENT_NAMES.OrdersLoaded, {
              order_count: orders.length,
              orders: orders,
            });
          }
          setActiveOrders(orders);
        }
      });
  }, [commerceData.id, phoneNumber]);

  useEffect(() => {
    EventsManager.getEventsManager();

    let pathInfo = window.location.pathname.split('/');
    let commerceIdPath = pathInfo[1];
    if (pathInfo.includes('producto')) {
      let urlVar = window.location.search ? window.location.search.slice(1) : null;
      if (urlVar) setUrlVariable(urlVar);
    } else if (pathInfo.includes('order-status')) {
      let urlVar = window.location.search ? window.location.search.slice(1) : null;
      if (urlVar) setUrlVariable(urlVar);
    } else if (window?.location?.search?.includes('tipo-captura')) {
      setOrigin(window.location.search.slice(1).split('=')[1]);
    }

    const pageController = PageController.getPageController({ baseUrl: '/' + commerceIdPath });
    pageController.pushPage({ page: HomePage, id: 'HomePage', state: homePageState, url: '' });

    const getMapsUrl = (commerce) =>
      commerce?.address?.latitude && commerce?.address?.longitude
        ? `https://www.google.com/maps/place/${commerce.address.latitude},${commerce.address.longitude}`
        : commerce.geolocalizacion?.latitud && commerce.geolocalizacion?.longitud
        ? `https://www.google.com/maps/place/${commerce.geolocalizacion.latitud},${commerce.geolocalizacion.longitud}`
        : null;

    const getCommerceGeoLocation = (commerce) => {
      if (commerce.address?.latitude && commerce.address?.longitude) {
        return {
          latitud: commerce.address.latitude,
          longitud: commerce.address.longitude,
        };
      }

      if (commerce.geolocalizacion?.latitud && commerce.geolocalizacion?.longitud) {
        return commerce.geolocalizacion;
      }

      return null;
    };

    (async () => {
      try {
        if (commerceIdPath) {
          const {
            comercio: commerce,
            categorias: categories,
            modulesAndPermissions,
            commerceIntegrations,
          } = await ApiServices.getCommerceData(commerceIdPath);
          if (commerce) {
            if (fbq && commerce.pixel_id) {
              try {
                fbq('init', commerce.pixel_id);
                fbq('track', 'PageView');
              } catch (error) {
                Sentry.captureException(error, {
                  tags: {
                    action: 'initiatingMetaPixel',
                  },
                });
              }
            }
            const cartController = CartController.getCartController(commerce.id);
            cartController.registerCommerceData({
              name: commerce.nombre,
              countryCode: commerce.country_code,
              phoneNumber: commerce.telefono,
              messageHeader:
                commerce?.plantilla_mensaje || 'Hola que tal, soy {nombre_cliente} y mi pedido es el siguiente:',
            });
            const paymentProvider = commerceIntegrations.find((provider) => provider.provider_type === 'payment');
            const paymentProviderKey = paymentProvider?.configuration?.public_key;
            const address = commerce.direccion || commerce?.address?.fullAddress || null;
            const mapsUrl = getMapsUrl(commerce);

            setCommerceData({
              id: commerce.id,
              id_path: commerce.id_path,
              planId: commerce.planId,
              name: commerce.nombre,
              isOpen: Boolean(commerce.abierto),
              categories: categories.map((category) => ({
                id: category.id,
                label: category.nombre,
              })),
              address,
              geolocalizacion: getCommerceGeoLocation(commerce),
              config_form_entrega: commerce?.config_form_entrega,
              modulesAndPermissions: modulesAndPermissions,
              availablePayMethods: {
                takeAway: commerce.metodos_pago_pasar_recoger && {
                  transfer: commerce.metodos_pago_pasar_recoger.transferencia,
                  cash: commerce.metodos_pago_pasar_recoger.efectivo,
                  card: commerce.metodos_pago_pasar_recoger.tarjeta,
                  online: commerce.metodos_pago_pasar_recoger.enlace_pago,
                  payment_provider: commerce.metodos_pago_pasar_recoger?.payment_provider || false,
                },
                inRestaurant: commerce.metodos_pago_comer_restaurant && {
                  transfer: commerce.metodos_pago_comer_restaurant.transferencia,
                  cash: commerce.metodos_pago_comer_restaurant.efectivo,
                  card: commerce.metodos_pago_comer_restaurant.tarjeta,
                  online: commerce.metodos_pago_comer_restaurant.enlace_pago,
                  payment_provider: commerce.metodos_pago_comer_restaurant?.payment_provider || false,
                },
                delivery: commerce.metodos_pago_envio_domicilio && {
                  transfer: commerce.metodos_pago_envio_domicilio.transferencia,
                  cash: commerce.metodos_pago_envio_domicilio.efectivo,
                  card: commerce.metodos_pago_envio_domicilio.tarjeta,
                  online: commerce.metodos_pago_envio_domicilio.enlace_pago,
                  payment_provider: commerce.metodos_pago_envio_domicilio?.payment_provider || false,
                },
              },
              availableDeliveryMethods: {
                delivery: commerce.activar_envio_domicilio,
                takeAway: commerce.activar_pasar_recoger,
                inRestaurant: commerce.activar_comer_restaurant,
                freeDelivery:
                  commerce.tipo_envio_domicilio === DELIVERY_TYPES_MAP.delivery_providers
                    ? false
                    : commerce.activar_envio_gratis_compra_minima,
                freeDeliveryAmount: commerce.importe_envio_gratis_compra_minima,
              },
              addTipsInput: Boolean(commerce.activar_campo_agregar_propina),
              deliveryPrice:
                commerce.tipo_envio_domicilio === 'estandar' || commerce.tipo_envio_domicilio === 'por_kilometro'
                  ? commerce.costo_envio_domicilio
                  : 0,
              informacion_envio:
                commerce.tipo_envio_domicilio === 'por_kilometro' ? { ...commerce.informacion_envio } : null,
              deliveryAreas:
                commerce.colonias &&
                commerce.colonias.map((area) => ({
                  name: area.nombre,
                  price: area.costo,
                })),
              deliveryType: DELIVERY_TYPES_MAP[commerce.tipo_envio_domicilio],
              deliveryPercentageQty:
                commerce.tipo_envio_domicilio === DELIVERY_TYPES_MAP.percent
                  ? commerce?.costo_por_porcentaje?.cantidad
                  : null,
              mapsUrl,
              logo: ApiServices.getImagePath(commerce.logotipo),
              schedule: commerce.horarios || [],
              countryCode: commerce.country_code,
              phone: commerce.telefono,
              paymentProvider,
              paymentProviderKey,
              socialNetworks: { facebook: commerce.url_facebook, instagram: commerce.url_instagram },
              bankingInformation: {
                name: commerce?.bank_transfer_details?.titular_name,
                bank: commerce?.bank_transfer_details?.bank_name,
                clabe: commerce?.bank_transfer_details?.clabe,
              },
              compra_minima_envio_domicilio:
                commerce?.tipo_envio_domicilio &&
                commerce.tipo_envio_domicilio !== 'gratis' &&
                commerce.tipo_envio_domicilio !== 'delivery_providers'
                  ? commerce?.compra_minima_envio_domicilio
                  : null,
            });
            trackEvent(TRACK_EVENT_NAMES.MenuCommerceLoaded, {
              commerce_loaded_is_open: Boolean(commerce.abierto),
            });
          }
        } else {
          setNotFound(true);
          loadingPageRef.current.style.opacity = 0;
          loadingPageRef.current.addEventListener(
            'transitionend',
            () => {
              setIsLoading(false);
            },
            { once: true },
          );
        }
      } catch (error) {
        window.console.error(error);
        Sentry.captureException(error, {
          tags: {
            action: 'loadingCommerceData',
          },
        });
        setNotFound(true);
        loadingPageRef.current.style.opacity = 0;
        loadingPageRef.current.addEventListener(
          'transitionend',
          () => {
            setIsLoading(false);
          },
          { once: true },
        );
        trackEvent(TRACK_EVENT_NAMES.MenuNotFound, {
          menu_not_found_id_path: commerceIdPath,
        });
      }
    })();
    return () => {
      PageController.unreferencePageController();
      CartController.unreferenceCartController();
      EventsManager.unreferenceEventsManager();
    };
  }, []);

  const onRefreshRequired = () => setCountToRefresh((currentCount) => currentCount + 1);

  useEffect(() => {
    const pageController = PageController.givePageController();
    const eventsManager = EventsManager.giveEventsManager();
    const onOrderStatusPageRequest = eventsManager.subscribe('onOrderStatusPageRequest', (activeOrder) => {
      pageController.pushPage({
        page: OrderStatusPage,
        id: 'OrderStatusPage',
        url: '/order-status',
        state: {
          activeOrders: activeOrders,
          commerceCountryCode: commerceData.countryCode,
          commercePhone: commerceData.phone,
          commerceName: commerceData.name,
          activeOrder: activeOrder ? activeOrders.findIndex((o) => o.orderId === activeOrder) : null,
          bankingInformation: commerceData.bankingInformation,
        },
      });
    });

    pageController.updatePageState({
      id: 'OrderStatusPage',
      state: {
        activeOrders: activeOrders,
        commerceCountryCode: commerceData.countryCode,
        commercePhone: commerceData.phone,
        commerceName: commerceData.name,
        bankingInformation: commerceData.bankingInformation,
      },
    });

    return () => {
      eventsManager.unsubscribe(onOrderStatusPageRequest);
    };
  }, [activeOrders, commerceData.phone, commerceData.name]);

  useEffect(() => {
    const pageController = PageController.givePageController();
    pageController.updatePageState({ id: 'HomePage', state: homePageState });
  }, [activeOrders]);

  useEffect(() => {
    let tokens;
    if (commerceData && commerceData.id) {
      let pathInfo = window.location.pathname.split('/');
      let commerceIdPath = pathInfo[1];

      const eventsManager = EventsManager.giveEventsManager();
      const pageController = PageController.givePageController();
      const cartController = CartController.giveCartController();

      tokens = {
        onPagePop: eventsManager.subscribe('onPagePop', onRefreshRequired),
        onPagePush: eventsManager.subscribe('onPagePush', onRefreshRequired),
        onPageStateUpdate: eventsManager.subscribe('onPageStateUpdate', onRefreshRequired),
        onCartButtonClick: eventsManager.subscribe('onCartButtonClick', () => {
          const products = cartController.getProducts();
          trackEvent(TRACK_EVENT_NAMES.CartView, {
            vista_carrito_cantidad_productos: products.length,
            vista_carrito_productos: products,
          });

          pageController.pushPage({
            page: CartPage,
            id: 'CartPage',
            state: {
              availableDeliveryMethods: commerceData.availableDeliveryMethods,
              deliveryPrice: commerceData.deliveryPrice,
            },
            url: '/carrito',
          });
        }),
        onInfoButtonClick: eventsManager.subscribe('onInfoButtonClick', () => {
          pageController.pushPage({
            page: InfoPage,
            id: 'InfoPage',
            state: {
              schedule: commerceData.schedule,
              socialNetworks: commerceData.socialNetworks,
              mapsUrl: commerceData.mapsUrl,
            },
            url: '/info',
          });
        }),
        onMapDialogRequest: eventsManager.subscribe('onMapDialogRequest', (data) => {
          trackEvent(TRACK_EVENT_NAMES.CartDeliveryLocationEditRequested, {
            carrito_ubicacion_editar_ubicacion_generica: data?.hasGenericLocation,
            carrito_ubicacion_editar_direccion: data?.fullAddress,
            carrito_ubicacion_editar_latitud: data?.location?.lat || '',
            carrito_ubicacion_editar_longitud: data?.location?.lng || '',
          });
          pageController.pushPage({
            page: MapPage,
            id: 'MapPage',
            state: {
              ...data,
              commerceLocation: commerceData.geolocalizacion,
              deliveryType: commerceData.deliveryType,
              modules: commerceData.modulesAndPermissions,
              origin,
            },
            url: '/map',
          });
        }),
        onAddProductPageRequest: eventsManager.subscribe('onAddProductPageRequest', (data) => {
          pageController.pushPage({
            page: ProductPage,
            id: 'ProductPage',
            state: { planId: commerceData.planId, ...data, extras: extraData },
            url: `/producto?id=${data.id}`,
          });
          trackEvent(TRACK_EVENT_NAMES.MenuProductView, {
            producto_id: data.id,
            producto_nombre: data.name,
            producto_precio: data.price,
            producto_cantidad: data.count,
            producto_disabled: data.isDisabled,
          });
        }),
        onDeliveryButtonClick: eventsManager.subscribe('onDeliveryButtonClick', (data) => {
          const subtotal = cartController.getTotal();
          const productos = cartController.getProducts();
          trackEvent(TRACK_EVENT_NAMES.CartProductsAccepted, {
            carrito_productos_cuenta: productos.length,
            carrito_productos_subtotal: subtotal,
            carrito_productos: productos,
          });

          pageController.pushPage({
            page: PersonalDataPage,
            id: 'PersonalDataPage',
            url: '/datos',
            state: {
              availableDeliveryMethods: {
                ...commerceData.availableDeliveryMethods,
                delivery:
                  commerceData.availableDeliveryMethods.delivery && typeof commerceData.deliveryType === 'string',
              },
              commerceAddress: commerceData.address,
              commerceMapsUrl: commerceData.mapsUrl,
              commerceId: commerceData.id,
              commerceLocation: commerceData.geolocalizacion,
              commerceCountryCode: commerceData.countryCode,
              commercePhone: commerceData.phone,
              commerceName: commerceData.name,
              paymentProviderKey: commerceData.paymentProviderKey,
              config_form_entrega: commerceData.config_form_entrega,
              deliveryAreas: commerceData.deliveryAreas,
              deliveryType: commerceData.deliveryType,
              deliveryPercentageQty: commerceData.deliveryPercentageQty,
              deliveryPrice: commerceData.deliveryPrice,
              informacion_envio: commerceData.informacion_envio,
              freeDelivery: commerceData.availableDeliveryMethods.freeDelivery,
              freeDeliveryAmount: commerceData.availableDeliveryMethods.freeDeliveryAmount,
              compra_minima_envio_domicilio: commerceData.compra_minima_envio_domicilio,
              ...data,
            },
          });
        }),
        onFinishDeliveryClick: eventsManager.subscribe('onFinishDeliveryClick', (data) => {
          trackEvent(TRACK_EVENT_NAMES.CartDeliveryCompleted, {
            carrito_entrega_metodo: data.deliveryMethod,
            carrito_entrega_gratis: data.freeDelivery,
            carrito_entrega_tipo: data.deliveryType,
            carrito_entrega_area: data.deliveryArea,
            carrito_entrega_precio: data.deliveryPrice,
            carrito_entrega_direccion: data?.personalData?.fullAddress || '',
            carrito_entrega_map_url: data?.personalData?.mapsUrl || '',
            carrito_entrega_latitud: data?.personalData?.location?.lat || '',
            carrito_entrega_longitud: data?.personalData?.location?.lng || '',
          });

          pageController.pushPage({
            page: DeliveryPage,
            id: 'DeliveryPage',
            url: '/entrega',
            state: {
              availablePayMethods: commerceData.availablePayMethods,
              addTipsInput: commerceData.addTipsInput,
              bankingInformation: commerceData.bankingInformation,
              commerceId: commerceData.id,
              commerceCountryCode: commerceData.countryCode,
              commercePhone: commerceData.phone,
              customerPhone: `+${data?.personalData?.countryCode}${data?.personalData?.phoneNumber}`,
              commerceName: commerceData.name,
              paymentProviderKey: commerceData.paymentProviderKey,
              paymentProvider: commerceData.paymentProvider,
              origin,
              setOrigin,
              ...data,
            },
          });
        }),
        onPageExit: eventsManager.subscribe('onPageExit', () => {
          pageController.popPage();
        }),
        displayNotification: eventsManager.subscribe('displayNotification', (data) => {
          pageController.displayNotification({
            page: Notification,
            id: 'Notification',
            state: data,
          });
        }),
        onApplicationLoadingComplete: eventsManager.subscribe('onApplicationLoadingComplete', () => {
          if (isLoading) {
            loadingPageRef.current.style.opacity = 0;
            loadingPageRef.current.addEventListener(
              'transitionend',
              () => {
                setIsLoading(false);
              },
              { once: true },
            );
            setTimeout(() => {
              setIsLoading(false);
            }, 500);
          }
        }),
        onShare: eventsManager.subscribe('onShare', async (value) => {
          const url = window.location.origin + '/' + commerceIdPath + '/' + value;
          try {
            const shareData = {
              text: 'Mira este producto de ' + commerceData.name,
              url: url,
            };
            await navigator.share(shareData);
          } catch (err) {
            try {
              await copyToClipboard(url);
              eventsManager.publish('displayNotification', { type: 'info', message: 'El enlace fue copiado' });
            } catch (err2) {
              eventsManager.publish('displayNotification', {
                type: 'error',
                message: 'No se pudo copiar el enlace, verifica el acceso al portapapeles',
              });
            }
          }
        }),
        onOrderRegisterSuccess: eventsManager.subscribe('onOrderRegisterSuccess', (data) => {
          trackEvent(TRACK_EVENT_NAMES.CartOrderCompleted, {
            carrito_pedido_folio: data.orderNumber,
            carrito_pedido_id: data.id,
            carrito_pedido_metodo_pago: data?.payMethod?.type,
            carrito_pedido_metodo_pago_monto: data?.payMethod?.value,
            carrito_pedido_cliente_nombre: data?.personalData?.name,
            carrito_pedido_cliente_direccion: data?.personalData?.fullAddres,
          });

          pageController.pushPage({
            page: OrderPage,
            id: 'OrderPage',
            url: '/pedido',
            state: {
              ...data,
              refLabel: commerceData?.config_form_entrega?.referencias?.label,
              commerceId: commerceData.id,
              commerceIdPath: commerceData.id_path,
              setActiveOrders: setActiveOrders,
              origin,
            },
          });
        }),
        onOrderPaymentFailed: eventsManager.subscribe('onOrderPaymentFailed', (data) => {
          trackEvent(TRACK_EVENT_NAMES.CartPaymentFailed, {
            payment_error: data,
          });
          pageController.pushPage({
            page: OrderFailedPage,
            id: 'OrderFailedPage',
            state: data,
          });
        }),
        onOnlinePaymentRequested: eventsManager.subscribe('onOnlinePaymentRequested', (data) => {
          pageController.pushPage({
            page: OnlinePaymentPage,
            id: 'OnlinePaymentPage',
            url: '/pago',
            state: data,
          });
        }),

        onWebsocketMessage: eventsManager.subscribe('onWebsocketMessage', (data) => {
          if (data.eventId === 'deliveryRiderAssigned') {
            trackEvent(TRACK_EVENT_NAMES.DeliveryRiderAssigned, {
              delivery_repartidor_nombre: data?.name,
              delivery_repartidor_telefono: data?.phone,
              delivery_repartidor_id: data?.id,
              delivery_repartidor_imagen: data?.image?.original || data?.image?.thumbnail,
            });
            setActiveOrders((prev) => {
              const newOrders = prev.map((order) => {
                if (order.id === data.orderId) {
                  return {
                    ...order,
                    deliveryDriverInfo: {
                      name: data?.name,
                      phone: data?.phone,
                      id: data?.id,
                      image: data?.image?.original || data?.image?.thumbnail,
                    },
                    deliveryTrackingUrl: data?.delivery_tracking_url,
                  };
                }
                return order;
              });
              return newOrders;
            });
          } else if (data.eventId === 'changeOrderStatus') {
            setActiveOrders((prev) => {
              const newOrders = prev.map((order) => {
                if (order.id === data.orderId) {
                  return {
                    ...order,
                    status: data.status,
                  };
                }
                return order;
              });
              return newOrders;
            });
          } else if (data.eventId === 'refresh') {
            const notificationInitialState = {
              title: 'Actualizamos Plick',
              description: 'Una nueva version de plick esta disponible',
              buttonLabel: 'Actualizar',
              preventClose: true,
              onButtonClick: () => {
                location.reload();
              },
            };
            pageController.pushPage({
              page: NotificationPage,
              id: 'MessageRefresh',
              url: '/mensaje',
              state: notificationInitialState,
            });
          } else if (data.eventId === 'changeCommerceStatus') {
            const isClosed = data.status !== 'activo';
            if (cartController && cartController.getProductsLength() > 0) {
              const notificationInitialState = {
                title: isClosed ? 'Cerramos' : 'Abrimos',
                description: isClosed ? 'Hemos cerrado temporalmente' : 'Ya abrimos, puedes enviarnos tu pedido',
                buttonLabel: 'Entendido',
                onButtonClick: () => {
                  let token = eventsManager.subscribe('onPagePop', () => {
                    setTimeout(() => {
                      eventsManager.unsubscribe(token);
                      eventsManager.publish('onGoToHome');
                    }, 100);
                  });
                  eventsManager.publish('onPageExit');
                },
              };
              pageController.pushPage({
                page: NotificationPage,
                id: 'MessageStatus',
                url: '/mensaje',
                state: notificationInitialState,
              });
            }
            setCommerceData({
              ...commerceData,
              isOpen: !isClosed,
            });
          } else if (data.eventId === 'changeProductStatus' && data.productId && data.status !== 'activo') {
            let cartProducts = cartController.getProducts();
            let products = cartProducts.filter((prd) => prd.id === data.productId);
            if (products && products.length) {
              const notificationInitialState = {
                title: 'Indisponible',
                description: `El producto "${products[0].name}" ya no está disponible`,
                buttonLabel: 'Borrar del carrito',
                onButtonClick: () => {
                  products.forEach((prd) => {
                    cartController.deleteProduct(prd.itemId);
                  });
                  let token = eventsManager.subscribe('onPagePop', () => {
                    eventsManager.unsubscribe(token);
                    eventsManager.publish('onGoToHome');
                  });
                  eventsManager.publish('onPageExitRequest', { id: 'Notification_Page' });
                },
              };
              pageController.pushPage({
                page: NotificationPage,
                id: 'MessageStatusPrd',
                url: '/mensaje',
                state: notificationInitialState,
              });
            } else if (window.location.search.includes(data.productId)) {
              const notificationInitialState = {
                title: 'Indisponible',
                description: 'Este producto ya no esta disponible',
                buttonLabel: 'Entendido',
                onButtonClick: () => {
                  eventsManager.publish('onPageExit');
                },
              };
              pageController.pushPage({
                page: NotificationPage,
                id: 'MessageStatusPrd',
                url: '/mensaje',
                state: notificationInitialState,
              });
            }
            eventsManager.publish('onProductAvailabilityChanged', data.productId);
          }
        }),
      };

      pageController.updatePageState({ id: 'HomePage', state: homePageState });
    }
    return () => {
      const eventsManager = EventsManager.giveEventsManager();
      if (eventsManager && tokens) {
        let keys = Object.keys(tokens);
        keys.forEach((key) => eventsManager.unsubscribe(tokens[key]));
        tokens = null;
      }
    };
  }, [commerceData, extraData]);

  useEffect(() => {
    if (!isLoading && urlVariable) {
      const [variable, identificator] = urlVariable.split('=');
      const eventsManager = EventsManager.giveEventsManager();

      if (eventsManager && variable === 'id') {
        eventsManager.publish('onDisplayProductRequest', identificator);
        let token = eventsManager.subscribe('onPageExit', () => {
          eventsManager.unsubscribe(token);
        });
      } else if (eventsManager && variable === 'folio') {
        eventsManager.publish('onDisplayOrderStatusRequest', identificator);
        let token = eventsManager.subscribe('onPageExit', () => {
          eventsManager.unsubscribe(token);
        });
      }
    }
  }, [isLoading, urlVariable]);

  useEffect(() => {
    if (commerceData && commerceData.id) {
      ApiServices.getCommerceNotification(commerceData.id).then(
        (notification) => {
          if (notification && notification.id) {
            const notificationInitialState = {
              title: notification ? notification.titulo : null,
              description: notification ? notification.descripcion : null,
              image: notification ? notification.imagen : null,
              buttonLabel: notification && notification.id_producto ? 'Ver Producto' : null,
              onButtonClick:
                notification && notification.id_producto
                  ? () => {
                      let eventsManager = EventsManager.giveEventsManager();
                      if (eventsManager) {
                        let token = eventsManager.subscribe('onPagePop', () => {
                          eventsManager.unsubscribe(token);
                          eventsManager.publish('onDisplayProductRequest', notification.id_producto);
                        });
                        eventsManager.publish('onPageExit');
                      }
                    }
                  : null,
            };
            const pageController = PageController.givePageController();
            if (pageController) {
              const { title, description, image, buttonLabel } = notificationInitialState;
              trackEvent(TRACK_EVENT_NAMES.MenuNotificationLoaded, {
                notificacion_title: title,
                notification_descripcion: description,
                notificacion_imagen: image,
                notificacion_buton_text: buttonLabel,
              });
              pageController.pushPage({
                page: NotificationPage,
                id: 'Notification',
                url: '/notificacion',
                state: notificationInitialState,
              });
            }
          }
        },
        (error) => {
          window.console.error('Error on getting notification:', error);
        },
      );
    }
  }, [commerceData.id]);

  useEffect(() => {
    if (navigator?.geolocation) {
      navigator.geolocation.getCurrentPosition(() => null);
    }
  }, []);

  const pageController = PageController.givePageController();

  return (
    <Theme>
      <GoogleMapsProvider isLoaded={isLoaded}>
        {isLoading && (
          <div ref={loadingPageRef} className="loading_page">
            <img src={logo} alt="Plick Logo" />
            <div className="loading_div">
              <div className="loading_wheel" />
            </div>
          </div>
        )}
        {!isNotFound ? (
          pageController &&
          pageController.getPages().map((pageData) => <pageData.page data={pageData.state} key={pageData.id} />)
        ) : (
          <Page404></Page404>
        )}
      </GoogleMapsProvider>
    </Theme>
  );
}

export default App;
