import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { countries } from '../../utils/countries';
import 'Styles/Select.scss';

const formatter = (country) => {
  return {
    value: country.countryCode,
    label: country.countryCode,
    flag: country.flag,
  };
};

// eslint-disable-next-line react/prop-types
export default function SelectCountries({ defaultValue, flag, onChange, isSearchable }) {
  const LATAM_COUNTRIES = countries.filter((country) => country.latinAmerica);

  const extraCountriesToAdd = ['US', 'ES'];
  const EXTRA_COUNTRIES = countries.filter((country) => extraCountriesToAdd.includes(country.code));

  const formattedLatamCountries = LATAM_COUNTRIES.map((country) => formatter(country));
  const formattedExtraCountries = EXTRA_COUNTRIES.map((country) => formatter(country));

  const formattedCountries = [...formattedLatamCountries, ...formattedExtraCountries];

  return (
    <>
      <Select
        isClearable={false}
        isSearchable={isSearchable}
        defaultValue={{
          value: defaultValue,
          label: (
            <div className="container-countries__select-format-option">
              <div className="container-countries__select-format-option__country_default">
                <img src={flag} className="container-countries__select-format-option__country-flag" />
                <span>{defaultValue}</span>
              </div>
            </div>
          ),
        }}
        options={formattedCountries}
        formatOptionLabel={(option) => (
          <div className="container-countries__select-format-option">
            <div className="container-countries__select-format-option__country">
              <img src={option.flag} className="container-countries__select-format-option__country-flag" />
              <span>{option.label}</span>
            </div>
          </div>
        )}
        classNames={{
          control: () => 'container-countries__select-control',
          input: () => 'container-countries__select-input',
          option: () => 'container-countries__select-option',
        }}
        theme={(theme) => ({
          ...theme,
          borderRadius: 8,
          colors: {
            ...theme.colors,
            primary: '#BFD3D9',
            primary25: '#e5ecee',
          },
        })}
        required
        onChange={onChange}
      />
    </>
  );
}

SelectCountries.prototype = {
  defaultValue: PropTypes.number.isRequired,
  flag: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  isSearchable: PropTypes.func.isRequired,
};
