import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import 'Styles/LineCheckBox.scss';
import CheckBox from 'Elements/CheckBox';
import CountButtons from 'Components/CountButtons';
import { formatCurrency } from '../../functions/money';

function LineCheckBox({ text, type, name, price, minValue, onChange, position, value = 0, disabled = false }) {
  const handleItemChange = useCallback(
    (state) => {
      onChange({ idx: position, state: state });
    },
    [onChange, position],
  );

  return (
    <div className="line_checkbox">
      <div className="line_checkbox-label">
        <label htmlFor={text} className={'line_checkbox-label--text' + (disabled ? '_disabled' : '')}>
          {text}
        </label>
        {price ? (
          <span className="line_checkbox-label--price" style={{ color: disabled ? '#adb6b9' : '' }}>
            {formatCurrency(price)}
          </span>
        ) : undefined}
      </div>
      {type === 'count' ? (
        <CountButtons value={value} onChange={handleItemChange} minValue={minValue} disabled={disabled} />
      ) : (
        <CheckBox
          id={text}
          type={type}
          name={name}
          isChecked={value !== 0}
          onChange={handleItemChange}
          disabled={disabled}
        />
      )}
    </div>
  );
}

export default memo(LineCheckBox);

LineCheckBox.propTypes = {
  text: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.oneOf(['checkbox', 'radio', 'count']),
  value: PropTypes.number,
  position: PropTypes.number,
  minValue: PropTypes.number,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  price: PropTypes.number,
};
