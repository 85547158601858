export const DELIVERY_MAP = {
  delivery: { key: 'delivery' },
  takeAway: { key: 'takeAway' },
  inRestaurant: { key: 'inRestaurant' },
};

export const DELIVERY_TYPES_MAP = {
  por_colonia: 'custom',
  gratis: 'free',
  estandar: 'standard',
  por_kilometro: 'by_km',
  delivery_providers: 'delivery_providers',
  percent: 'percent',
};

export const ORDER_STATUS_MAP = {
  pending: { key: 'pending', label: 'Tu pedido se generó con éxito.', icon: 'fluent:receipt-20-filled' },
  accepted: { key: 'accepted', label: 'Tu pedido está en preparación', icon: 'mdi:food' },
  arrived_at_pickup: {
    key: 'arrived_at_pickup',
    label: 'El repartidor ha llegado a recoger el pedido',
    icon: 'icon-park-solid:delivery',
  },
  on_the_way: { key: 'on_the_way', label: 'Tu pedido ya va en camino', icon: 'fontisto:motorcycle' },
  arrived_at_delivery: {
    key: 'arrived_at_delivery',
    label: 'El repartidor ha llegado',
    icon: 'heroicons:home-20-solid',
  },
  delivered: { key: 'delivered', label: 'Tu pedido fue entregado ¡BUEN PROVECHO!', icon: 'fa6-solid:check' },
  ready_to_pick_up: {
    key: 'ready_to_pick_up',
    label: 'Tu pedido está listo para recoger',
    icon: 'streamline:travel-hotel-bell-service-concierge-porter-call-ring-bellhop-bell-reception',
  },
};

export const STATUS_ORDER_DELIVERY = ['pending', 'accepted', 'on_the_way', 'delivered'];
export const STATUS_ORDER_TAKE_AWAY = ['pending', 'accepted', 'ready_to_pick_up', 'delivered'];
export const STATUS_ORDER_IN_RESTAURANT = ['pending', 'accepted', 'delivered'];
export const STATUS_ORDER_DELIVERY_PROVIDERS = [
  'pending',
  'accepted',
  'arrived_at_pickup',
  'on_the_way',
  'arrived_at_delivery',
  'delivered',
];

export const ALLOWED_PAYMENT_METHODS_MAP = {
  Tarjeta: {
    key: 'Tarjeta',
  },
  Efectivo: {
    key: 'Efectivo',
  },
  Transferencia: {
    key: 'Transferencia',
  },
  enlace_pago: {
    key: 'enlace_pago',
  },
  payment_provider: {
    key: 'payment_provider',
  },
};

export const ALL_ALLOWED_PAYMENT_METHODS = Object.keys(ALLOWED_PAYMENT_METHODS_MAP);

export const MODULES_MAP = {
  GEOLOCALIZACION_AVANZADA: 'GEOLOCALIZACION_AVANZADA',
};
