import React, { memo, useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import FlatButton from 'Elements/FlatButton';
import 'Styles/Page.scss';
import EventsManager from 'Controllers/EventsManager';
import { useDesktopMode } from 'Hooks/hooks';

function Page({ id, preventClose, children }) {
  const pageDiv = useRef(null);
  const pageBackdropRef = useRef(null);
  const handleBackClick = useCallback((data) => {
    if (!preventClose && (!data.id || data.id === id)) {
      pageDiv.current.classList.add('closed');
      pageDiv.current.addEventListener(
        'animationend',
        () => {
            let eventsManager = EventsManager.giveEventsManager();
          if (eventsManager) eventsManager.publish('onPageExit');
          if (pageBackdropRef && pageBackdropRef.current)
          pageBackdropRef.current.style.display = 'none';

        },
        { once: true },
      );
    }
  }, []);

  const isDesktop = useDesktopMode();

  useEffect(() => {
    let token;
    let eventsManager = EventsManager.giveEventsManager();
    if (eventsManager) token = eventsManager.subscribe('onPageExitRequest', handleBackClick);
    return () => {
      if (eventsManager) token = eventsManager.unsubscribe(token);
    };
  }, []);
  return (
    <>
      {isDesktop && <div ref={pageBackdropRef} className="page-backdrop" onClick={handleBackClick} />}
      <div ref={pageDiv} className="page">
        {children}
        {!preventClose && (
          <FlatButton
            icon={isDesktop ? 'eva:close-outline' : 'uil:angle-left'}
            className="page-back_button"
            onClick={handleBackClick}
          />
        )}
      </div>
    </>
  );
}

export default memo(Page);

Page.propTypes = {
  id: PropTypes.string.isRequired,
  preventClose: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};
