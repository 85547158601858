import React, { useEffect, useState } from 'react';
import { Icon } from '@iconify/react';
import { roundAmount } from '../../../functions/money';
import PropTypes from 'prop-types';
import EventsManager from 'Controllers/EventsManager';
import { CardPayment } from '@mercadopago/sdk-react';
import { initMercadoPago } from '@mercadopago/sdk-react';
import { mercadoPagoCheckout } from './mercadoPagoCheckout';
import { PayDisclaimer } from '../PayDisclaimer';

export const MercadoPagoCard = ({
  total,
  createOrder,
  commerceId,
  providerCode,
  customerPhone,
  paymentProviderKey,
}) => {
  const [isMPScriptReady, setIsMPScriptReady] = useState(false);
  const [isMPReady, setIsMPReady] = useState(true);
  const [isCreatingOrder, setIsCreatingOrder] = useState(false);

  useEffect(() => {
    const init = async () => {
      if (!paymentProviderKey) {
        return;
      }

      try {
        initMercadoPago(paymentProviderKey);
        await new Promise((resolve) => setTimeout(resolve, 1000));
        setIsMPScriptReady(true);
      } catch (error) {
        const eventsManager = EventsManager.giveEventsManager();
        if (eventsManager)
          eventsManager.publish('displayNotification', {
            type: 'error',
            message: 'No se pudo cargar Mercado Pago',
          });
      }
    };

    init();
  }, [paymentProviderKey]);

  if (!isMPScriptReady || isCreatingOrder) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '10px',
        }}
      >
        {isCreatingOrder ? 'Estamos enviando tu pedido...' : null}
        <Icon icon="line-md:loading-twotone-loop" width={40} />
      </div>
    );
  }

  return (
    <>
      {!isMPReady ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Icon icon="line-md:loading-twotone-loop" width={40} />
        </div>
      ) : null}
      <div style={{ display: isMPReady ? 'block' : 'none' }}>
        <CardPayment
          customization={{
            visual: {
              hideFormTitle: true,
              style: {
                theme: 'bootstrap',
              },
            },
            paymentMethods: {
              maxInstallments: 1,
            },
          }}
          onReady={() => setIsMPReady(true)}
          initialization={{
            amount: roundAmount(total),
            payer: {
              email: 'cliente@ejemplo.com',
            },
          }}
          onSubmit={(formData) => {
            mercadoPagoCheckout({
              createOrder,
              setIsCreatingOrder,
              formData,
              providerCode,
              commerceId,
              amount: total,
              phone: customerPhone,
            });
          }}
        />
        <PayDisclaimer />
      </div>
    </>
  );
};

MercadoPagoCard.propTypes = {
  total: PropTypes.number.isRequired,
  createOrder: PropTypes.func.isRequired,
  commerceId: PropTypes.string.isRequired,
  customerPhone: PropTypes.string.isRequired,
  providerCode: PropTypes.string.isRequired,
  paymentProviderKey: PropTypes.string.isRequired,
};
