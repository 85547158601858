import React from 'react';
import '../assets/css/DeliveryRider.scss';
import { Icon } from '@iconify/react';
import PropTypes from 'prop-types';
import ApiServices from 'ApiServices/ApiServices';

const LoadingItem = () => <div className="delivery_rider-loading-item"></div>;

export const DeliveryRider = ({ deliveryDriverInfo }) => {
  return (
    <div className="delivery_rider">
      <p className="delivery_rider-title">{!deliveryDriverInfo ? 'Buscando repartidor' : 'Repartidor asignado'}</p>
      <div className="delivery_rider-data">
        <div className="delivery_rider-data-image">
          {deliveryDriverInfo?.image ? (
            <img src={deliveryDriverInfo?.image} />
          ) : (
            <Icon icon="healthicons:ui-user-profile-negative" color="#E5ECEE" />
          )}
        </div>
        <div className="delivery_rider-data-details">
          {!deliveryDriverInfo ? (
            <>
              <LoadingItem />
              <LoadingItem />
              <LoadingItem />
            </>
          ) : (
            <>
              <p className="delivery_rider-data-details-name">{deliveryDriverInfo?.name}</p>
              <p className="delivery_rider-data-details-id">ID de repartidor: {deliveryDriverInfo?.id}</p>
              <p
                className="delivery_rider-data-details-whatsapp"
                onClick={() => ApiServices.sendWhatsappMessage(deliveryDriverInfo?.phone, '')}
              >
                <span>
                  <Icon icon="ic:baseline-whatsapp" width="18px" height="18px" />
                </span>
                Enviar WhatsApp: {deliveryDriverInfo?.phone}
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

DeliveryRider.propTypes = {
  deliveryDriverInfo: PropTypes.exact({
    name: PropTypes.string,
    id: PropTypes.string,
    phone: PropTypes.string,
    image: PropTypes.string,
  }),
};
