import React, { memo } from 'react';
import Dialog from 'Elements/Dialog';
import PropTypes from 'prop-types';
import logoCommerce from 'Images/logo.svg';
import 'Styles/InfoPage.scss';
import FlatButton from 'Elements/FlatButton';

const days = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];

function getScheduleStr(scheduleObj) {
  let result = '';
  scheduleObj.turns.forEach((turn, idx) => {
    if (idx === 0) result += `${turn.open}h-${turn.closed}h`;
    else if (idx === scheduleObj.turns.length - 1) result += ` y ${turn.open}h-${turn.closed}h`;
    else result += `, ${turn.open}h-${turn.closed}h`;
  });
  return result;
}

function timeToMinutes(time) {
  const [hours, minutes] = time.split(':');
  return parseInt(hours) * 60 + parseInt(minutes);
}

function InfoPage({ data }) {
  const { schedule, socialNetworks, mapsUrl } = data;
  schedule.sort((a, b) => {
    if (!parseInt(a.dia_semana) && parseInt(b.dia_semana)) return 1;
    if (parseInt(a.dia_semana) && !parseInt(b.dia_semana)) return -1;
    if (parseInt(a.dia_semana) < parseInt(b.dia_semana)) return -1;
    if (parseInt(a.dia_semana) > parseInt(b.dia_semana)) return 1;
    return Math.sign(timeToMinutes(a.horario_apertura) - timeToMinutes(b.horario_cierre));
  });

  let workDays = [];
  schedule.forEach((day) => {
    const dayIndex = parseInt(day.dia_semana);
    const turn = workDays.find((workTurn) => workTurn.dayIndex === dayIndex);
    if (turn) {
      turn.turns.push({ open: day.horario_apertura, closed: day.horario_cierre });
    } else {
      workDays.push({
        dayIndex: dayIndex,
        turns: [{ open: day.horario_apertura, closed: day.horario_cierre }],
      });
    }
  });

  return (
    <Dialog>
      <div className="info_page">
        <span className="info_page-sub_title">Horario:</span>
        <div className="info_page-schedules">
          {days.map((day, idx) => {
            const dayIndex = (idx + 1) % 7; // Sunday is 0 and we need to display it at the end
            const workDay = workDays.find((wd) => wd.dayIndex === dayIndex);
            return (
              <div
                key={'planning' + idx}
                className={dayIndex === new Date().getDay() ? 'info_page-schedules-today' : 'info_page-schedules'}
              >
                <span key={'planning-day' + idx} className="info_page-schedules-day">
                  {days[dayIndex]}
                </span>
                <span
                  key={'planning-schedule' + idx}
                  className={workDay ? 'info_page-schedules-day-schedule' : 'info_page-schedules-day-closed'}
                >
                  {workDay ? getScheduleStr(workDay) : 'Cerrado'}
                </span>
              </div>
            );
          })}
        </div>
        {socialNetworks && <span className="info_page-sub_title">Redes Sociales:</span>}
        {socialNetworks && (
          <div className="info_page-social_networks">
            {Object.keys(socialNetworks).map(
              (socialNetwork, index) =>
                socialNetworks[socialNetwork] && (
                  <FlatButton
                    key={'social-' + index}
                    className="info_page--icons"
                    icon={'uil:' + socialNetwork}
                    onClick={() => window.open(socialNetworks[socialNetwork], '_blank')}
                  />
                ),
            )}
          </div>
        )}
        {mapsUrl && <span className="info_page-sub_title">Ubicación:</span>}
        {mapsUrl && (
          <FlatButton
            className="info_page--icons"
            icon="el:map-marker"
            onClick={() => window.open(mapsUrl, '_blank')}
          />
        )}
        <span>Version 1.0.2</span>
      </div>
      <div className="info_page-footer" onClick={() => window.open('https://www.plick.com.mx/', '_blank')}>
        <span>Contrata</span>
        <img src={logoCommerce} alt="Logo de plick" />
        <span>aquí</span>
      </div>
    </Dialog>
  );
}

export default memo(InfoPage);

InfoPage.propTypes = {
  data: PropTypes.exact({
    schedule: PropTypes.array.isRequired,
    socialNetworks: PropTypes.exact({
      facebook: PropTypes.string,
      instagram: PropTypes.string,
    }).isRequired,
    mapsUrl: PropTypes.string.isRequired,
  }),
};
