/* global fbq */
import React, { useState, useCallback, memo, useEffect } from 'react';
import EventsManager from 'Controllers/EventsManager';
import CartItem from 'Components/CartItem';
import CartController from 'Controllers/CartController';
import { Icon } from '@iconify/react';
import BarButton from 'Elements/BarButton';
import Card from 'Elements/Card';
import Page from 'Elements/Page';
import 'Styles/CartPage.scss';
import { formatCurrency } from '../functions/money';

function CartPage() {
  const cartController = CartController.giveCartController();
  const [{ products, total }, setCartData] = useState({
    products: cartController ? cartController.getProducts() : [],
    total: cartController ? cartController.getTotal() : 0,
  });

  useEffect(() => {
    let token;
    const eventsManager = EventsManager.giveEventsManager();
    if (eventsManager)
      token = eventsManager.subscribe('onCartModified', () => {
        setCartData({
          products: cartController.getProducts(),
          total: cartController.getTotal(),
        });
      });
    return () => {
      if (eventsManager && token) eventsManager.unsubscribe(token);
    };
  });

  const handleDeliveryClick = useCallback(() => {
    if (products.length === 0) {
      const eventsManager = EventsManager.giveEventsManager();
      if (eventsManager)
        eventsManager.publish('displayNotification', { type: 'error', message: 'El carrito esta vacio' });
      return;
    }
    let eventsManager = EventsManager.giveEventsManager();
    if (eventsManager) eventsManager.publish('onDeliveryButtonClick');
    if (fbq) fbq('trackCustom', 'CartButton');
  }, [products.length]);

  const handleItemEdit = useCallback(async (itemId) => {
    if (cartController) {
      await cartController.editProduct(itemId);
      setCartData({
        products: cartController.getProducts(),
        total: cartController.getTotal(),
      });
    }
  }, []);

  const handleItemDelete = useCallback((itemId) => {
    if (cartController) {
      cartController.deleteProduct(itemId);
      setCartData({
        products: cartController.getProducts(),
        total: cartController.getTotal(),
      });
    }
  }, []);

  return (
    <>
      <Page id="Cart_Page">
        <div className="cart-container">
          <div className="cart-header">
            <Icon icon="uil:shopping-cart" />
            <p>Carrito</p>
          </div>
          <div className="cart-content">
            <Card>
              {products &&
                products.map((item, idx) => (
                  <React.Fragment key={'CartItem-' + item.itemId}>
                    <CartItem
                      id={item.itemId}
                      title={item.name}
                      count={item.count}
                      directions={item.directions}
                      option={item.option}
                      sections={item.sections}
                      suggestions={item.suggestions}
                      onEdit={handleItemEdit}
                      onDelete={handleItemDelete}
                    />
                    {idx < products.length - 1 && <div key={'CartItemBar-' + idx} className="cart_page-item_bar" />}
                  </React.Fragment>
                ))}
            </Card>
          </div>
          <BarButton onClick={handleDeliveryClick} disabled={products.length === 0}>
            <div className="cart_page-bar_button">
              <div className="home_page-bar_button--icon">
                <div className="home_page-bar_button--items">{products.length}</div>
                <Icon icon="uil:shopping-cart" />
              </div>
              <span className="cart_page-bar_button--label">
                <div>Total:</div>
                <div>{formatCurrency(total)}</div>
              </span>
              <div className="cart_page-bar_button--bar" />
              <span className="cart_page-bar_button--next">Datos de Entrega</span>
              <Icon icon="uil:angle-right" />
            </div>
          </BarButton>
        </div>
      </Page>
    </>
  );
}

export default memo(CartPage);
