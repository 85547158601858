import React, { memo, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import 'Styles/Notification.scss';
import EventsManager from 'Controllers/EventsManager';

function Notification({ data }) {
  const notificationDiv = useRef(null);
  const timeBar = useRef(null);

  useEffect(() => {
    timeBar.current.addEventListener(
      'animationend',
      () => {
        notificationDiv.current.classList.remove('displayAnimation');
        window.requestAnimationFrame(() => {
          notificationDiv.current.classList.add('closeAnimation');
          notificationDiv.current.addEventListener(
            'animationend',
            () => {
              notificationDiv.current.style.display = 'none';
              let eventsManager = EventsManager.giveEventsManager();
              if (eventsManager) eventsManager.publish('onNotificationExit');
            },
            { once: true },
          );
        });
      },
      { once: true },
    );
  }, []);

  return (
    <div ref={notificationDiv} className={'notification displayAnimation ' + data.type}>
      <div className="notification-message">{data.message}</div>
      <div ref={timeBar} className="notification-bar"></div>
    </div>
  );
}

export default memo(Notification);

Notification.propTypes = {
  data: PropTypes.exact({
    type: PropTypes.oneOf(['error', 'info']),
    message: PropTypes.string,
  }),
};
