import PropTypes from 'prop-types';
import { countries } from './countries';

export const getFlagByCountryCode = (countryCode) => {
  const foundCountry = countries.find((country) => country.countryCode === countryCode);
  return foundCountry ? foundCountry.flag : null;
};

getFlagByCountryCode.prototype = {
  countryCode: PropTypes.number.isRequired,
};
