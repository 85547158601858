import React from 'react';
import Dialog from 'Elements/Dialog';
import { Icon } from '@iconify/react';
import PropTypes from 'prop-types';
import { useDesktopMode } from '../hooks/hooks';
import Page from 'Elements/Page';
import RoundedRectangleButton from 'Elements/RoundedRectangleButton';
import 'Styles/NotificationPage.scss';

function NotificationPage({ data }) {
  const isDesktop = useDesktopMode();
  const Wrapper = isDesktop ? Page : Dialog;
  const imageSize = data.buttonLabel ? 'small' : data.title ? 'medium' : 'large';

  return (
    <>
      <Wrapper id="Notification_Page" preventClose={data.preventClose}>
        <div className="notification-container">
          {(data.image && (
            <img
              className={'notification-image notification-image-' + imageSize}
              alt="Imagen de anuncio"
              src={data.image}
            />
          )) || <Icon className="notification-icon" icon="healthicons:alert-circle-outline" />}
          {data.title && <div className="notification-title">{data.title}</div>}
          {data.description && <div className="notification-description">{data.description}</div>}
          {data.buttonLabel && (
            <RoundedRectangleButton
              className="notification-button"
              label={data.buttonLabel}
              icon="uil:angle-right"
              onClick={data.onButtonClick}
            />
          )}
        </div>
      </Wrapper>
    </>
  );
}

export default NotificationPage;

NotificationPage.propTypes = {
  data: PropTypes.exact({
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
    buttonLabel: PropTypes.string,
    onButtonClick: PropTypes.func,
    preventClose: PropTypes.bool,
  }),
};
