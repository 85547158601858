export const formatCurrency = (amount) => {
  if (typeof amount !== 'number' || isNaN(amount)) {
    return '';
  }

  const decimals = (amount.toString().split('.')[1] || '').slice(0);

  let currencyValue = amount;

  if (decimals.length > 2) {
    currencyValue = Math.floor(amount * 100) / 100;
  }

  const converted = Intl.NumberFormat('es-MX', {
    style: 'currency',
    currency: 'MXN',
  }).format(currencyValue);

  return converted;
};

export const roundAmount = (value) => {
  return Math.round((value + Number.EPSILON) * 100) / 100;
};
