import React, { useRef, useCallback, memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import '../assets/css/TabBar.scss';
import { Icon } from '@iconify/react';
import { useDesktopMode } from '../hooks/hooks';

function TabBar({ items, currentIndex, onItemChange }) {
  const scroller = useRef(null);
  const leftArrow = useRef(null);
  const rightArrow = useRef(null);

  const isDesktop = useDesktopMode();

  const handleScroll = useCallback(() => {
    if (scroller.current) {
      if (isDesktop) {
        if (scroller.current.scrollTop > 5) leftArrow.current.classList.remove('hidden');
        else leftArrow.current.classList.add('hidden');
        if (scroller.current.scrollHeight - scroller.current.scrollTop - scroller.current.clientHeight < 5)
          rightArrow.current.classList.add('hidden');
        else rightArrow.current.classList.remove('hidden');
      } else {
        if (scroller.current.scrollLeft > 30) leftArrow.current.classList.remove('hidden');
        else leftArrow.current.classList.add('hidden');
        if (scroller.current.scrollWidth - scroller.current.scrollLeft - scroller.current.clientWidth < 30)
          rightArrow.current.classList.add('hidden');
        else rightArrow.current.classList.remove('hidden');
      }
    }
  }, [isDesktop]);

  const handleMoreClick = useCallback(
    (from) => {
      if (scroller.current) {
        let amount = (isDesktop ? scroller.current.offsetHeight : scroller.current.offsetWidth) / 2;
        let scrollTo =
          (isDesktop ? scroller.current.scrollTop : scroller.current.scrollLeft) +
          (from === 'right' ? amount : -amount);
        // let child = scroller.current.querySelectorAll('button')[currentIndex];
        // let tabRect = child.getBoundingClientRect();
        scroller.current.scroll({
          left: isDesktop ? undefined : scrollTo,
          top: isDesktop ? scrollTo : undefined,
          behavior: 'smooth',
        });
      }
    },
    [isDesktop],
  );

  useEffect(() => {
    window.addEventListener('resize', handleScroll);
    handleScroll();
    return () => {
      window.removeEventListener('resize', handleScroll);
    };
  }, [isDesktop]);

  useEffect(() => {
    if (scroller.current) {
      let child = scroller.current.querySelectorAll('button')[currentIndex];
      if (child) {
        let tabRect = child.getBoundingClientRect();
        scroller.current.scroll({
          left: isDesktop
            ? undefined
            : scroller.current.scrollLeft + tabRect.x + (tabRect.width + 10) / 2 - scroller.current.clientWidth / 2,
          top: isDesktop
            ? scroller.current.scrollTop + tabRect.y + tabRect.height / 2 - scroller.current.clientHeight / 2 - 230
            : undefined,
          behavior: 'smooth',
        });
      }
    }
  }, [currentIndex, isDesktop]);

  return (
    <div ref={scroller} onScroll={handleScroll} className="tab_bar">
      <div
        ref={leftArrow}
        onClick={() => {
          handleMoreClick('left');
        }}
        className="tab_bar-more hidden left"
      >
        <Icon icon={isDesktop ? 'uil:angle-up' : 'uil:angle-left'} />
      </div>
      {items.map((item, idx) => (
        <button
          key={item.label}
          className="tab_bar-item"
          onClick={() => {
            onItemChange(idx);
          }}
          current-section={currentIndex === idx ? 'true' : 'false'}
        >
          <span>{item.label}</span>
        </button>
      ))}
      <div
        ref={rightArrow}
        onClick={() => {
          handleMoreClick('right');
        }}
        className="tab_bar-more right"
      >
        <Icon icon={isDesktop ? 'uil:angle-down' : 'uil:angle-right'} />
      </div>
    </div>
  );
}

export default memo(TabBar);

TabBar.propTypes = {
  currentIndex: PropTypes.number.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.exact({
      label: PropTypes.string,
      id: PropTypes.string,
    }),
  ).isRequired,
  onItemChange: PropTypes.func.isRequired,
};
