export const statusDetails = {
  Accredited: 'Pago acreditado',
  pending_contingency: 'El pago está siendo procesado',
  pending_review_manual: 'El pago está bajo revisión para determinar su aprobación o rechazo',
  cc_rejected_bad_filled_date: 'Fecha de vencimiento incorrecta',
  cc_rejected_bad_filled_other: 'Detalles de la tarjeta incorrectos',
  cc_rejected_bad_filled_security_code: 'Código de seguridad (CVV) incorrecto',
  cc_rejected_blacklist: 'La tarjeta está en una lista negra por robo/quejas/fraude',
  cc_rejected_call_for_authorize: 'El medio de pago requiere autorización previa del monto de la operación',
  cc_rejected_card_disabled: 'La tarjeta está inactiva',
  cc_rejected_duplicated_payment: 'Transacción duplicada',
  cc_rejected_high_risk: 'Rechazo por Prevención de Fraude',
  cc_rejected_insufficient_amount: 'Monto insuficiente',
  cc_rejected_invalid_installments: 'Número de cuotas inválido',
  cc_rejected_max_attempts: 'Se excedió el número máximo de intentos',
  cc_rejected_other_reason: 'No se pudo procesar el pago',
};
