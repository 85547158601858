import React, { useEffect } from 'react';
import type { FC, ReactNode } from 'react';

type ColorScheme = {
  colors: {
    component: string;
    component_dark: string;
    component_light: string;
  };
};

type ThemeProps = {
  children: ReactNode;
};

const Theme: FC<ThemeProps> = ({ children }) => {
  useEffect(() => {
    let pathInfo = window.location.pathname.split('/');
    let commerceIdPath = pathInfo[1];

    const fetchTheme = async () => {
      try {
        if (!commerceIdPath) return;

        const url = `${API_URL}/v1/public/assets/${commerceIdPath}`;

        const response = await fetch(url);
        const colorData: ColorScheme = await response.json();

        const rootStyle = document.documentElement.style;
        (Object.keys(colorData.colors) as (keyof typeof colorData.colors)[]).forEach((key) => {
          rootStyle.setProperty('--color-' + key.replaceAll('_', '-'), colorData.colors[key]);
        });
      } catch (error) {
        console.error('Error fetching theme:', error);
      }
    };

    fetchTheme();
  }, []);

  return <>{children}</>;
};

export default Theme;
