import React, { useState } from 'react';
import { Icon } from '@iconify/react';
import PropTypes from 'prop-types';
import 'Styles/Accordion.scss';

function Accordion({ title, expanded = false, children }) {
  const [show, setShow] = useState(expanded);
  return (
    <div className="accordion">
      <div
        className="accordion-title"
        onClick={() => {
          setShow((prev) => !prev);
        }}
      >
        <p className="accordion-title-text">{title}</p>
        <span className={`accordion-title-icon ${show && 'show'}`}>
          <Icon icon="ep:arrow-down" width="16px" height="16px" />
        </span>
      </div>
      <div className={`accordion-content ${show && 'show'}`}>{children}</div>
    </div>
  );
}

export default Accordion;

Accordion.propTypes = {
  title: PropTypes.string,
  expanded: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};
