import React, { useEffect, useState } from 'react';
import { Elements, PaymentElement } from '@stripe/react-stripe-js';
import { StripePaymentElement } from './PaymentElement';
import { Icon } from '@iconify/react';
import { roundAmount } from '../../../functions/money';
import PropTypes from 'prop-types';
import ApiServices from 'ApiServices/ApiServices';
import { loadStripe } from '@stripe/stripe-js';
import EventsManager from 'Controllers/EventsManager';

export const StripeCard = ({
  paymentProviderKey,
  total,
  commerceId,
  customerPhone,
  providerCode,
  onStripe,
  onStripeElements,
  setIsReady,
}) => {
  const [stripeIntent, setStripeIntent] = useState(null);
  const [stripeObject, setStripeObject] = useState(null);
  const [stripeError, setStripError] = useState(false);
  const [stripeReady, setStripeReady] = useState(false);

  useEffect(() => {
    setIsReady(stripeReady);
  }, [stripeReady]);

  useEffect(() => {
    const requestStripeIntent = async () => {
      setStripError(false);
      setStripeReady(false);

      try {
        setStripeIntent(null);
        await new Promise((resolve) => setTimeout(resolve, 100));
        const intent = await ApiServices.getIntent({
          commerceId: commerceId,
          amount: roundAmount(total),
          phone: customerPhone,
          providerCode,
        });
        setStripeIntent(intent.client_secret);
      } catch (error) {
        setStripError(true);
      }
    };

    requestStripeIntent();
  }, [total]);

  useEffect(() => {
    const loadStripeCode = async () => {
      if (stripeObject || !paymentProviderKey) {
        return;
      }

      try {
        const stripeScript = await loadStripe(paymentProviderKey);
        setStripeObject(stripeScript);
      } catch (error) {
        const eventsManager = EventsManager.giveEventsManager();
        if (eventsManager)
          eventsManager.publish('displayNotification', {
            type: 'error',
            message: 'No se pudo cargar Stripe',
          });
      }
    };

    loadStripeCode();
  }, [paymentProviderKey, stripeObject]);

  return (
    <>
      {stripeIntent && stripeObject && !stripeError ? (
        <Elements
          stripe={stripeObject}
          options={{
            clientSecret: stripeIntent,
          }}
        >
          <div style={{ display: stripeReady ? 'block' : 'none' }}>
            <PaymentElement onReady={() => setStripeReady(true)} />
          </div>
          {!stripeReady ? (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Icon icon="line-md:loading-twotone-loop" width={40} />
            </div>
          ) : (
            <StripePaymentElement onStripe={onStripe} onElements={onStripeElements} />
          )}
        </Elements>
      ) : stripeError ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <p style={{ color: 'red', textAlign: 'center' }}>
            No se pudo generar tu pago, intenta con otro método, si el problema persiste contacta al comercio
          </p>
        </div>
      ) : (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Icon icon="line-md:loading-twotone-loop" width={40} />
        </div>
      )}
    </>
  );
};

StripeCard.propTypes = {
  total: PropTypes.number.isRequired,
  paymentProviderKey: PropTypes.string.isRequired,
  commerceId: PropTypes.string.isRequired,
  customerPhone: PropTypes.string.isRequired,
  providerCode: PropTypes.string.isRequired,
  onStripe: PropTypes.func.isRequired,
  onStripeElements: PropTypes.func.isRequired,
  setIsReady: PropTypes.func.isRequired,
};
