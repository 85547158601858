export const appCache = {
  set name(name) {
    localStorage.setItem('PlickPersonalData-name', name);
  },
  get name() {
    return localStorage.getItem('PlickPersonalData-name') || '';
  },
  set fullAddress(address) {
    localStorage.setItem('PlickPersonalData-full-address', address);
  },
  get fullAddress() {
    return localStorage.getItem('PlickPersonalData-full-address') || '';
  },
  address: {
    set zone(zone) {
      localStorage.setItem('PlickPersonalData-address-zone', zone);
    },
    get zone() {
      return localStorage.getItem('PlickPersonalData-address-zone') || '';
    },
    set street(street) {
      localStorage.setItem('PlickPersonalData-address-street', street);
    },
    get street() {
      return localStorage.getItem('PlickPersonalData-address-street') || '';
    },
    set intNumber(intNumber) {
      localStorage.setItem('PlickPersonalData-address-intNumber', intNumber);
    },
    get intNumber() {
      return localStorage.getItem('PlickPersonalData-address-intNumber') || '';
    },
    set outNumber(outNumber) {
      localStorage.setItem('PlickPersonalData-address-outNumber', outNumber);
    },
    get outNumber() {
      return localStorage.getItem('PlickPersonalData-address-outNumber') || '';
    },
    set ref(ref) {
      localStorage.setItem('PlickPersonalData-address-ref', ref);
    },
    get ref() {
      return localStorage.getItem('PlickPersonalData-address-ref') || '';
    },
  },
  set tableNumber(tableNumber) {
    localStorage.setItem('PlickPersonalData-tableNumber', tableNumber);
  },
  get tableNumber() {
    return localStorage.getItem('PlickPersonalData-tableNumber') || '';
  },
  set phoneNumber(phoneNumber) {
    localStorage.setItem('PlickPersonalData-phoneNumber', phoneNumber);
  },
  get phoneNumber() {
    return localStorage.getItem('PlickPersonalData-phoneNumber') || '';
  },
  set countryCode(countryCode) {
    localStorage.setItem('PlickPersonalData-countryCode', countryCode);
  },
  get countryCode() {
    return localStorage.getItem('PlickPersonalData-countryCode') || 52;
  },
  set location(location) {
    localStorage.setItem('PlickPersonalData-location-lat', location.lat);
    localStorage.setItem('PlickPersonalData-location-lng', location.lng);
  },
  get location() {
    return localStorage.getItem('PlickPersonalData-location-lat') &&
      localStorage.getItem('PlickPersonalData-location-lng')
      ? {
          lat: Number(localStorage.getItem('PlickPersonalData-location-lat')),
          lng: Number(localStorage.getItem('PlickPersonalData-location-lng')),
        }
      : undefined;
  },
  set mapsUrl(mapsUrl) {
    localStorage.setItem('PlickPersonalData-mapsUrl', mapsUrl);
  },
  get mapsUrl() {
    return localStorage.getItem('PlickPersonalData-mapsUrl') || undefined;
  },
  set deliveryType(deliveryType) {
    localStorage.setItem('PlickPersonalData-deliveryType', deliveryType);
  },
  get deliveryType() {
    return localStorage.getItem('PlickPersonalData-deliveryType') || undefined;
  },
  set deliveryAreaIdx(deliveryAreaIdx) {
    localStorage.setItem('PlickPersonalData-deliveryAreaIdx', deliveryAreaIdx);
  },
  get deliveryAreaIdx() {
    return parseInt(localStorage.getItem('PlickPersonalData-deliveryAreaIdx')) ?? -1;
  },
};
