import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import RoundedRectangleButton from './elements/RoundedRectangleButton';
import '../assets/css/CountButtons.scss';

function CountButtons({ value, onChange, minValue = 0, disabled = false }) {
  const handlePlusClick = useCallback(() => onChange(true), [onChange]);
  const handleMinusClick = useCallback(() => onChange(false), [onChange]);

  return (
    <div className="count_buttons">
      <RoundedRectangleButton
        className="count_buttons-minus"
        icon="uil:minus"
        onClick={handleMinusClick}
        disabled={value <= minValue}
      />
      <span>{value}</span>
      <RoundedRectangleButton
        className="count_buttons-plus"
        icon="uil:plus"
        onClick={handlePlusClick}
        disabled={disabled}
      />
    </div>
  );
}

export default memo(CountButtons);

CountButtons.propTypes = {
  value: PropTypes.number,
  minValue: PropTypes.number,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};
