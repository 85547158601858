import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import 'Styles/Card.scss';

function Card({ title, subTitle, error = false, errorMessage = 'Requerido', children }) {
  const cardRef = useRef(null);

  useEffect(() => {
    let removeAnimation = () => {
      if (cardRef.current) cardRef.current.classList.remove('animateBoxError');
    };
    if (cardRef.current) cardRef.current.addEventListener('animationend', removeAnimation);
    return () => {
      if (cardRef.current) {
        cardRef.current.removeEventListener('animationend', removeAnimation);
      }
    };
  }, []);

  return (
    <div ref={cardRef} className={'card' + (error ? ' animateBoxError' : '')} box_error={error.toString()}>
      {title && (
        <div className="card-title">
          <span className="card-title--required">{error ? errorMessage : ''}</span>
          <span>{title}</span>
          {subTitle && <span className="card-subtitle">{subTitle}</span>}
        </div>
      )}
      <div className="card-content" box_error={error.toString()}>
        {children}
      </div>
    </div>
  );
}

export default Card;

Card.propTypes = {
  title: PropTypes.string,
  errorMessage: PropTypes.string,
  subTitle: PropTypes.string,
  error: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};
