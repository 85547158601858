/* eslint-disable complexity */
/* global fbq */
/* global PUBLIC_API_URL */
import axios from 'axios';
import React, { useState, useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import MethodPay from 'Elements/MethodPay';
import StringInput from 'Elements/StringInput';
import 'Styles/DeliveryPage.scss';
import Comments from 'Elements/Comments';
import CartController from 'Controllers/CartController';
import Page from 'Elements/Page';
import BarButton from 'Elements/BarButton';
import { Icon } from '@iconify/react';
import RadioButtons from 'Components/RadioButtons';
import Card from 'Elements/Card';
import EventsManager from 'Controllers/EventsManager';
import RoundedRectangleButton from 'Elements/RoundedRectangleButton';
import { formatCurrency, roundAmount } from '../functions/money';
import { ALLOWED_PAYMENT_METHODS_MAP, ALL_ALLOWED_PAYMENT_METHODS } from '../utils/constants';
import ApiServices from 'ApiServices/ApiServices';
import { PaymentProvider } from '../components/payment/PaymentProvider';
import { usePaymentProviders } from '../components/payment/usePaymentProviders';
import * as Sentry from '@sentry/browser';

const dataCache = {
  name: '',
  address: '',
  comments: '',
  tableNumber: '',
  payValue: 0,
  deliveryAreaIdx: -1,
};

function DeliveryPage({ data }) {
  const [deliveryData, setDeliveryData] = useState({
    name: dataCache.name,
    address: dataCache.address,
    comments: dataCache.comments,
    deliveryMethod: data.deliveryMethod,
    tableNumber: dataCache.tableNumber,
    personalData: data.personalData,
    deliveryType: data.deliveryType,
    tips: 0,
  });
  const [isProcessing, setIsProcessing] = useState(false);
  const [displayTipsInput, setDisplayTipsInput] = useState(false);
  const [discount, setDiscount] = useState(0);
  const [coupon, setCoupon] = useState('');
  const [isValidatingCoupon, setIsValidatingCoupon] = useState(false);
  const [couponError, setCouponError] = useState('');
  const { stripe, stripeElements, setStripe, setStripeElements, doPayment } = usePaymentProviders();
  const [isPaymentProviderReady, setIsPaymentProviderReady] = useState(false);

  const { commerceCountryCode, commercePhone, commerceName } = data;

  const availablePayMethods = data.availablePayMethods[data.deliveryMethod];
  let defaultPayType = availablePayMethods.cash
    ? 'cash'
    : availablePayMethods.transfer
    ? 'transfer'
    : availablePayMethods.card
    ? 'card'
    : availablePayMethods.payment_provider
    ? 'payment_provider'
    : availablePayMethods.online
    ? 'online'
    : '';

  const [payMethod, setPayMethod] = useState({ type: defaultPayType, value: dataCache.payValue });
  const [errorState, setErrorState] = useState(false);
  const hasAllowedPaymentMethods =
    (data?.allowedPaymentMethods &&
      data.allowedPaymentMethods.length > 0 &&
      ((availablePayMethods.card && data.allowedPaymentMethods.includes(ALLOWED_PAYMENT_METHODS_MAP.Tarjeta.key)) ||
        (availablePayMethods.cash && data.allowedPaymentMethods.includes(ALLOWED_PAYMENT_METHODS_MAP.Efectivo.key)) ||
        (availablePayMethods.transfer &&
          data.allowedPaymentMethods.includes(ALLOWED_PAYMENT_METHODS_MAP.Transferencia.key)) ||
        (availablePayMethods.online &&
          data.allowedPaymentMethods.includes(ALLOWED_PAYMENT_METHODS_MAP.enlace_pago.key)))) ||
    (availablePayMethods.payment_provider &&
      data.allowedPaymentMethods.includes(ALLOWED_PAYMENT_METHODS_MAP.payment_provider.key));

  const scroller = useRef(null);

  const handleOrderClick = useCallback(async () => {
    setIsProcessing(true);
    let total = 0,
      cartTotal = 0;
    const cartController = CartController.giveCartController();
    if (cartController) cartTotal = cartController.getTotal();
    total += cartTotal;
    if (data.deliveryMethod === 'delivery' && data.deliveryPrice) total += data.deliveryPrice;
    total += deliveryData.tips;
    if (discount) {
      total -= discount;
    }

    total = roundAmount(total);

    if (payMethod.type === 'cash' && (!payMethod.value || payMethod.value < total)) {
      if (errorState) {
        let errorBoxes = scroller.current.querySelectorAll('[box_error="true"]');
        scroller.current.scroll({
          top: errorBoxes[0].offsetTop - 20,
          behavior: 'smooth',
        });
        for (let ii = 0; ii < errorBoxes.length; ii++) {
          const element = errorBoxes[ii];
          element.classList.add('animateBoxError');
        }
      }
      setErrorState(true);
      setIsProcessing(false);
      return;
    }

    if (payMethod.type === 'payment_provider') {
      const response = await doPayment({ provider_code: data?.paymentProvider?.provider_code });

      if (typeof response === 'string') {
        setIsProcessing(false);
        const eventsManager = EventsManager.giveEventsManager();
        if (eventsManager) {
          eventsManager.publish('displayNotification', {
            type: 'error',
            message: response || 'Error al procesar el pago, contacta al comercio',
          });
        }

        return;
      }
    }

    if (cartController) {
      if (fbq)
        fbq('track', 'Purchase', { value: total, num_items: cartController.getProductsLength(), currency: 'MXN' });
      try {
        let order = {
          ...deliveryData,
          personalData: data.personalData,
          payMethod: payMethod,
          freeDelivery: data.freeDelivery,
          freeDeliveryAmount: data.freeDeliveryAmount,
          deliveryPrice: data.deliveryPrice,
          delivery_quote_distance_meters: data?.delivery_quote_distance_meters,
          delivery_quote_response: data?.delivery_quote_response,
          deliveryArea: data.deliveryArea,
          discount: discount,
          couponCode: !couponError ? coupon : null,
          providerCode: data?.providerCode,
          address: data?.address
        };
        let orderData = await cartController.registerOrder(order, { origin: data?.origin, setOrigin: data?.setOrigin });
        if (orderData && orderData.folio) {
          if (orderData.metodo_pago === 'enlace_pago') {
            if (orderData.enlace_pago) {
              const eventsManager = EventsManager.giveEventsManager();
              if (eventsManager) {
                eventsManager.publish('onOnlinePaymentRequested', {
                  url: orderData.enlace_pago,
                  orderId: orderData.id,
                });
                let onSuccess;
                let tokenExit = eventsManager.subscribe('onPageExit', () => {
                  eventsManager.unsubscribe(tokenExit);
                  setIsProcessing(false);
                  if (onSuccess) onSuccess();
                });
                let token = eventsManager.subscribe('onWebsocketMessage', (socketData) => {
                  if (socketData && socketData.eventId === 'changeOrderStatus' && socketData.orderId === orderData.id) {
                    eventsManager.unsubscribe(token);
                    if (socketData.status === 'pagado') {
                      onSuccess = () => {
                        setTimeout(() => {
                          if (eventsManager)
                            eventsManager.publish('onOrderRegisterSuccess', {
                              ...order,
                              orderNumber: orderData.folio,
                              orderId: orderData.id,
                            });
                        }, 100);
                      };
                      eventsManager.publish('onPageExitRequest', { id: 'OnlinePayment_Page' });
                    }
                  }
                });
              }
            } else {
              const eventsManager = EventsManager.giveEventsManager();
              if (eventsManager)
                eventsManager.publish('onOrderPaymentFailed', {
                  handleTryAgain: () => {
                    eventsManager.publish('onPageExitRequest', { id: 'OrderFailedPage' });
                    handleOrderClick();
                  },
                  handleChangeMethod: () => {
                    eventsManager.publish('onPageExitRequest', { id: 'OrderFailedPage' });
                  },
                });
              setIsProcessing(false);
            }
          } else {
            setTimeout(() => {
              const eventsManager = EventsManager.giveEventsManager();
              if (eventsManager)
                eventsManager.publish('onOrderRegisterSuccess', {
                  ...order,
                  orderNumber: orderData.folio,
                  orderId: orderData.id,
                });
              setIsProcessing(false);
            }, 500);
          }
        } else throw new Error('Order cannot be saved');
      } catch (error) {
        Sentry.setContext('MenuContext', {
          ApirequestId: error?.response?.data?.requestId || 'N/A',
          ApilogStreamName: error?.response?.data?.logStreamName || 'N/A',
          apiMessage: error?.response?.data?.errorMessage || 'N/A',
          userName: deliveryData?.personalData?.name || 'N/A',
          userPhone: deliveryData?.personalData?.phoneNumber || 'N/A',
          numberProducts: cartController.getProductsLength(),
        });
        Sentry.captureException(error, {
          tags: {
            action: 'createOrder',
          },
        });
        setTimeout(() => {
          const eventsManager = EventsManager.giveEventsManager();
          if (eventsManager)
            eventsManager.publish('displayNotification', {
              type: 'error',
              message: 'El pedido no pudo ser registrado',
            });
          setIsProcessing(false);
        }, 500);
      }
    }
  }, [
    deliveryData,
    payMethod,
    setIsProcessing,
    errorState,
    coupon,
    couponError,
    discount,
    stripe,
    stripeElements,
    data?.paymentProvider?.provider_code,
  ]);

  useEffect(() => {
    if (errorState) {
      let errorBox = scroller.current.querySelector('[box_error="true"]');
      scroller.current.scroll({
        top: errorBox.offsetTop - 20,
        behavior: 'smooth',
      });
    }
  }, [errorState]);

  let total = 0,
    cartTotal = 0;
  const cartController = CartController.giveCartController();
  if (cartController) cartTotal = cartController.getTotal();
  total += cartTotal;
  if (data.deliveryMethod === 'delivery' && data.deliveryPrice) total += data.deliveryPrice;
  total += deliveryData.tips;
  if (discount) {
    total -= discount;
  }

  const tips = [
    { label: '$ 0', value: 0 },
    { label: '$ 10', value: 10 },
    { label: '$ 20', value: 20 },
    { label: '$ 30', value: 30 },
    { label: '$ 40', value: 40 },
    { label: 'Otro', value: null },
  ];

  const payMethodErrorState = errorState && payMethod.type === 'cash' && (!payMethod.value || payMethod.value < total);

  const validateCoupon = async () => {
    const genericError = 'Error al validar el cupón';
    setIsValidatingCoupon(true);
    setCouponError('');
    try {
      let resp = await axios.post(`${PUBLIC_API_URL}/v1/validate/coupon`, {
        id_comercio: data.commerceId,
        code: coupon,
        amount: cartTotal,
      });

      if (!resp?.data?.discount) {
        setCouponError(genericError);
        return;
      }

      setDiscount(resp.data.discount);
    } catch (error) {
      setCouponError(error?.response?.data?.message || genericError);
    } finally {
      setIsValidatingCoupon(false);
    }
  };

  const clearCoupon = () => {
    setCoupon('');
    setDiscount(0);
  };

  const isButtonDisabled =
    !payMethod.type ||
    isProcessing ||
    !hasAllowedPaymentMethods ||
    (payMethod.type === 'payment_provider' && !isPaymentProviderReady);

  const isPayingWithMercadoPago =
    payMethod.type === 'payment_provider' && data?.paymentProvider?.provider_code === 'mercadoPago';
  const isPayingWithClip = payMethod.type === 'payment_provider' && data?.paymentProvider?.provider_code === 'clip';

  return (
    <Page id="Delivery_Page">
      <div ref={scroller} className="delivery_page">
        <p className="delivery_page-title">Completa los datos</p>
        {data.addTipsInput && (
          <Card title="Propina:">
            <RadioButtons
              items={tips.map((item) => item.label)}
              current={0}
              name={'propina'}
              onChange={(itemNumber) => {
                if (tips[itemNumber].value === null) {
                  setDisplayTipsInput(true);
                  setDeliveryData({ ...deliveryData, tips: 0 });
                } else {
                  setDisplayTipsInput(false);
                  setDeliveryData({ ...deliveryData, tips: tips[itemNumber].value });
                }
              }}
            />
            {displayTipsInput && (
              <div style={{ width: '100%', padding: '0 25px', display: 'flex', alignItems: 'center' }}>
                <span style={{ fontSize: '19px', marginRight: '7px', marginTop: '2px' }}>$</span>
                <StringInput
                  placeHolder="Propina"
                  type="number"
                  name="input propina"
                  value={deliveryData.tips.toString()}
                  onValueChange={(value) => {
                    setDeliveryData({ ...deliveryData, tips: parseFloat(value) || 0 });
                  }}
                />
              </div>
            )}
          </Card>
        )}
        <Card title="Cupón de descuento" error={couponError} errorMessage={couponError}>
          <div className="coupon-input">
            <StringInput
              placeHolder="Código del cupón"
              value={coupon}
              disabled={discount}
              onValueChange={(val) => {
                setCouponError('');
                setCoupon(val);
              }}
              maxLength={10}
            />
            <RoundedRectangleButton
              disabled={!coupon || isValidatingCoupon}
              className="submit_button"
              label={!discount ? 'Validar' : 'Borrar'}
              onClick={() => (!discount ? validateCoupon() : clearCoupon())}
            />
          </div>
          <div className="coupon-status">
            {!couponError && discount > 0 ? (
              <div className="row">
                <Icon icon="mdi:coupon-outline" color="#028d46" width="22px" />
                <p className="coupon-success-label">Cupón aplicado</p>
              </div>
            ) : null}
          </div>
        </Card>
        <Card title="Detalle del pedido">
          <div className="order-details-detailed">
            <div className="row">
              <p>Total del pedido:</p>
              <p>{formatCurrency(cartTotal)}</p>
            </div>
            {discount ? (
              <div className="row">
                <p>Descuento:</p>
                <p>- {formatCurrency(discount)}</p>
              </div>
            ) : null}
            <div className="row">
              <p>Costo del envío:</p>
              <p>
                +{' '}
                {data.deliveryMethod === 'delivery' && data.deliveryPrice
                  ? formatCurrency(data.deliveryPrice)
                  : formatCurrency(0)}
              </p>
            </div>
            <div className="row">
              <p>Propina:</p>
              <p>+ {formatCurrency(deliveryData.tips)}</p>
            </div>
            <h2>Total a pagar: {formatCurrency(total)}</h2>
          </div>
        </Card>
        <Card
          title="Método de pago"
          subTitle="Selecciona el de tu preferencia"
          error={payMethodErrorState}
          errorMessage={payMethodErrorState ? (!payMethod.value ? 'Requerido' : 'El monto es inferior al total') : ''}
        >
          {!hasAllowedPaymentMethods ? (
            <p style={{ color: 'red', textAlign: 'center' }}>
              Este comercio no tiene métodos de pago disponibles para esta orden, por favor comunícate con ellos.
              <span
                style={{ fontWeight: 700, display: 'block', marginTop: '5px', cursor: 'pointer' }}
                onClick={() => {
                  ApiServices.sendWhatsappMessage(
                    commerceCountryCode,
                    commercePhone,
                    `Hola ${commerceName} tengo un problema para realizar mi pedido a través de Plick, me podrían ayudar?`,
                  );
                }}
              >
                Da clic aquí para contactarlos
              </span>
            </p>
          ) : (
            <MethodPay
              error={payMethodErrorState}
              currentPayMethod={payMethod}
              allowedPaymentMethods={data.allowedPaymentMethods}
              payMethods={availablePayMethods}
              total={total}
              bankingInformation={data.bankingInformation}
              onValueChange={(value) => {
                dataCache.payValue = value.value;
                setPayMethod(value);
              }}
            />
          )}
        </Card>
        {payMethod.type === 'payment_provider' ? (
          <div style={{ width: '100%' }}>
            <Card title="Información de pago">
              <div style={{ padding: '0px 5px', marginTop: '5px' }} className="stripe_card">
                <PaymentProvider
                  commerceId={data.commerceId}
                  customerPhone={data.customerPhone}
                  customerOnlyPhone={data.personalData.phoneNumber}
                  providerCode={data?.paymentProvider?.provider_code}
                  total={total}
                  paymentProviderKey={data.paymentProviderKey}
                  onStripe={setStripe}
                  onStripeElements={setStripeElements}
                  setIsReady={setIsPaymentProviderReady}
                  createOrder={handleOrderClick}
                />
              </div>
            </Card>
          </div>
        ) : null}
        <Card title="Comentarios:">
          <Comments
            placeHolder="Ejemplo: No agregar servilletas - No agregar cubiertos"
            value={deliveryData.comments}
            onValueChange={(value) => {
              dataCache.comments = value;
              setDeliveryData({ ...deliveryData, comments: value });
            }}
          />
        </Card>
        <div className="terms">
          <p className="terms_text">
            Al hacer clic en{' '}
            <span className="terms_text-span">
              {isPayingWithMercadoPago || isPayingWithClip ? 'Pagar' : 'Validar Pedido'}
            </span>{' '}
            acepto los{' '}
            <a
              rel="noreferrer"
              target="_blank"
              href="https://www.plick.com.mx/terminosycondiciones"
              className="terms_text-a"
            >
              Términos y Condiciones
            </a>{' '}
            así como el{' '}
            <a
              href="https://www.plick.com.mx/avisoprivacidad"
              rel="noreferrer"
              target="_blank"
              className="terms_text-a"
            >
              Aviso de Privacidad.
            </a>{' '}
          </p>
        </div>
      </div>
      {isPayingWithMercadoPago || isPayingWithClip ? null : (
        <BarButton onClick={isButtonDisabled ? () => null : handleOrderClick} disabled={isButtonDisabled}>
          <div className="product_page-bar_button">
            <Icon icon={isProcessing ? 'line-md:loading-twotone-loop' : 'akar-icons:check'} />
            <span>{isProcessing ? 'Validando' : 'Validar'} Pedido</span>
          </div>
        </BarButton>
      )}
    </Page>
  );
}

export default DeliveryPage;

DeliveryPage.propTypes = {
  data: PropTypes.exact({
    origin: PropTypes.string,
    setOrigin: PropTypes.func,
    deliveryPrice: PropTypes.number.isRequired,
    delivery_quote_distance_meters: PropTypes.number || null,
    delivery_quote_response: PropTypes.string || null,
    commerceId: PropTypes.string.isRequired,
    commercePhone: PropTypes.number.isRequired,
    customerPhone: PropTypes.string,
    commerceCountryCode: PropTypes.number.isRequired,
    commerceName: PropTypes.string.isRequired,
    deliveryMethod: PropTypes.string.isRequired,
    allowedPaymentMethods: PropTypes.arrayOf(PropTypes.oneOf(ALL_ALLOWED_PAYMENT_METHODS)).isRequired,
    deliveryType: PropTypes.string.isRequired,
    addTipsInput: PropTypes.bool,
    freeDelivery: PropTypes.bool,
    freeDeliveryAmount: PropTypes.number,
    deliveryArea: PropTypes.oneOfType([
      PropTypes.exact({
        name: PropTypes.string.isRequired,
        price: PropTypes.number.isRequired,
      }),
      PropTypes.bool,
    ]),
    availablePayMethods: PropTypes.exact({
      takeAway: PropTypes.exact({
        transfer: PropTypes.bool,
        cash: PropTypes.bool,
        card: PropTypes.bool,
        online: PropTypes.bool,
        payment_provider: PropTypes.bool,
      }),
      inRestaurant: PropTypes.exact({
        transfer: PropTypes.bool,
        cash: PropTypes.bool,
        card: PropTypes.bool,
        online: PropTypes.bool,
        payment_provider: PropTypes.bool,
      }),
      delivery: PropTypes.exact({
        transfer: PropTypes.bool,
        cash: PropTypes.bool,
        card: PropTypes.bool,
        online: PropTypes.bool,
        payment_provider: PropTypes.bool,
      }),
    }).isRequired,
    personalData: PropTypes.exact({
      name: PropTypes.string,
      location: PropTypes.exact({
        lat: PropTypes.number,
        lng: PropTypes.number,
      }),
      address: PropTypes.exact({
        zone: PropTypes.string,
        street: PropTypes.string,
        intNumber: PropTypes.string,
        outNumber: PropTypes.string,
        ref: PropTypes.string,
      }),
      fullAddress: PropTypes.string,
      tableNumber: PropTypes.string,
      mapsUrl: PropTypes.string,
      phoneNumber: PropTypes.string,
      countryCode: PropTypes.string,
    }).isRequired,
    bankingInformation: PropTypes.exact({
      name: PropTypes.string,
      bank: PropTypes.string,
      clabe: PropTypes.string,
    }),
    providerCode: PropTypes.string,
    paymentProviderKey: PropTypes.string,
    paymentProvider: PropTypes.exact({
      provider_code: PropTypes.string,
      provider_type: PropTypes.string,
      configuration: PropTypes.object,
    }),
    address: PropTypes.exact({
      zipCode: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      country: PropTypes.string,
      latitude: PropTypes.string,
      longitude: PropTypes.string,
      fullAddress: PropTypes.string,
      street: PropTypes.string,
      neighborhood: PropTypes.string,
      interiorNumber: PropTypes.string,
      exteriorNumber: PropTypes.string,
    }),
  }),
};
