import * as Sentry from '@sentry/react';
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CartController from 'Controllers/CartController';
import 'Styles/OrderPage.scss';
import { Icon } from '@iconify/react';
import Dialog from 'Elements/Dialog';
import useTracking, { TRACK_EVENT_NAMES } from '../hooks/useTracking';


function OrderPage({ data }) {
  const [countdown, setCountdown] = useState(5);
  const { trackEvent } = useTracking();

  const handleOrderClick = useCallback(() => {
    try {
      let cartController = CartController.giveCartController();
      if (cartController) {
        cartController.order(data);
        trackEvent(TRACK_EVENT_NAMES.CartOrderSentViaWhatsApp, {
          order_number: data.orderNumber,
          order_id: data.orderId,
        });
      }
    } catch (error) {
      Sentry.captureException(error, {
        tags: {
          component: 'OrderPage',
          action: 'handleOrderClick',
        },
      });
    }


  }, [data]);

  useEffect(() => {
    if (!data?.origin || data.origin !== 'android' || !data.commerceIdPath) return;

    const timer = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown === 1) {
          clearInterval(timer);
          window.location.replace('/' + data.commerceIdPath + '?tipo-captura=android');
          return 0;
        }
        return prevCountdown - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [data?.origin, data?.commerceIdPath]);

  const isOriginPhone = data?.origin;

  return (
    <Dialog preventClose={true} fullWidth={false}>
      {isOriginPhone ? (
        <div className="register-container">
          <div className="register-success_icon" style={{ borderColor: '#00C04B' }}>
            <Icon icon="ep:success-filled" style={{ color: '#00C04B' }} />
          </div>
          <span className="register-title">
            Pedido registrado <br /> con éxito
          </span>
          <span className="register-subtitle">Éste es el número del pedido:</span>
          <span className="register-order_number">{data.orderNumber}</span>
          {isOriginPhone === 'android' ? (
            <span style={{ marginTop: '20px' }}>Serás redirigido en {countdown} segundos</span>
          ) : null}
        </div>
      ) : (
        <div className="register-container">
          <div className="register-success_icon">
            <Icon icon="tabler:hand-click" />
          </div>
          <span className="register-title">
            Dale clic en ENVIAR <br /> para finalizar la compra
          </span>
          <button className="register-button" onClick={handleOrderClick}>
            <div className="register-bar_button">
              <Icon icon="uil:whatsapp" />
              <span>ENVIAR</span>
            </div>
          </button>
          <span className="register-subtitle">Éste es el número de tu pedido:</span>
          <span className="register-order_number">{data.orderNumber}</span>
        </div>
      )}
    </Dialog>
  );
}

export default OrderPage;

OrderPage.propTypes = {
  data: PropTypes.exact({
    origin: PropTypes.string,
    orderNumber: PropTypes.string.isRequired,
    orderId: PropTypes.string.isRequired,
    commerceId: PropTypes.string.isRequired,
    commerceIdPath: PropTypes.string.isRequired,
    name: PropTypes.string,
    address: PropTypes.string,
    comments: PropTypes.string,
    deliveryMethod: PropTypes.string,
    deliveryType: PropTypes.string,
    tableNumber: PropTypes.string,
    refLabel: PropTypes.string,
    personalData: PropTypes.exact({
      name: PropTypes.string,
      location: PropTypes.exact({
        lat: PropTypes.number.isRequired,
        lng: PropTypes.number.isRequired,
      }),
      mapsUrl: PropTypes.string,
      address: PropTypes.exact({
        zone: PropTypes.string,
        street: PropTypes.string,
        intNumber: PropTypes.string,
        outNumber: PropTypes.string,
        ref: PropTypes.string,
      }),
      tableNumber: PropTypes.string,
      phoneNumber: PropTypes.string,
      countryCode: PropTypes.string,
      fullAddress: PropTypes.string,
    }),
    tips: PropTypes.number,
    payMethod: PropTypes.exact({
      type: PropTypes.string,
      value: PropTypes.number,
    }),
    freeDelivery: PropTypes.bool,
    freeDeliveryAmount: PropTypes.number,
    deliveryPrice: PropTypes.number,
    deliveryArea: PropTypes.oneOfType([
      PropTypes.exact({
        name: PropTypes.string.isRequired,
        price: PropTypes.number.isRequired,
      }),
      PropTypes.bool,
    ]),
    setActiveOrders: PropTypes.func.isRequired,
  }),
};
