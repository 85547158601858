import React, { memo, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import LineCheckBox from './elements/LineCheckBox';
import '../assets/css/ListBox.scss';
import LoadingLine from './elements/LoadingLine';
import Card from 'Elements/Card';

function ListBox({ title, type = 'checkbox', content, maxValues, onLineChange, isOnError = false, disabled = false }) {
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleItemChange = useCallback(
    ({ idx, state }) => {
      let newOpts = selectedOptions.slice();
      if (type === 'radio') newOpts.forEach((opt) => (opt.count = 0));
      let opt = newOpts[idx];
      opt.count = state ? opt.count + 1 : opt.count - 1;
      onLineChange(newOpts);
    },
    [selectedOptions],
  );

  useEffect(() => {
    let newContent = content.map((item) =>
      typeof item === 'string'
        ? item
        : {
            ...item,
          },
    );
    setSelectedOptions(newContent);
  }, [content]);

  const currentCount = selectedOptions.reduce((acc, val) => acc + val.count, 0);
  const error = isOnError && currentCount < maxValues;

  return (
    <Card title={title} subTitle={!disabled && maxValues > 1 ? `(${maxValues})` : ''} error={error}>
      {selectedOptions &&
        selectedOptions.map((line, idx) => {
          return typeof line === 'string' ? (
            <LoadingLine key={'LoadingLineItem' + idx} />
          ) : (
            <LineCheckBox
              key={'LineItem' + idx}
              position={idx}
              name={title}
              type={type}
              text={line.label}
              price={line.price}
              value={line.count}
              disabled={
                disabled ||
                line.isDisabled ||
                (currentCount >= maxValues && (type === 'count' || (type === 'checkbox' && line.count === 0)))
              }
              onChange={handleItemChange}
            />
          );
        })}
    </Card>
  );
}

export default memo(ListBox);

ListBox.propTypes = {
  title: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  isOnError: PropTypes.bool,
  type: PropTypes.oneOf(['checkbox', 'radio', 'count']),
  content: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.exact({
        id: PropTypes.string,
        label: PropTypes.string,
        price: PropTypes.number,
        isDisabled: PropTypes.bool,
        count: PropTypes.number,
      }),
    ),
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  maxValues: PropTypes.number,
  onLineChange: PropTypes.func.isRequired,
};
