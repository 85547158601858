import React, { memo } from 'react';
import PropTypes from 'prop-types';
import 'Styles/Comments.scss';

function Comments({ title, value, placeHolder, onValueChange, error = false }) {
  return (
    <div className="string_input-container">
      {title && <span className="string_input-title">{title}</span>}
      <textarea
        className={'text-area' + (error ? ' on-error' : '')}
        value={value}
        placeholder={placeHolder}
        onChange={(event) => {
          onValueChange(event.target.value);
        }}
      ></textarea>
    </div>
  );
}

export default memo(Comments);

Comments.propTypes = {
  title: PropTypes.string,
  placeHolder: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.bool,
  onValueChange: PropTypes.func.isRequired,
};

