import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import RoundedRectangleButton from './elements/RoundedRectangleButton';
import '../assets/css/Cart.scss';
import { formatCurrency } from '../functions/money';
import Accordion from './elements/Accordion';

function CartItem({ id, title, count, directions, option, sections, suggestions, onEdit, onDelete }) {
  const handleEdit = useCallback(() => onEdit(id), [id]);
  const handleDelete = useCallback(() => onDelete(id), [id]);

  let price = '';

  if (option.price) {
    const operation = option.price * count;
    price = formatCurrency(operation);
  } else {
    price = '';
  }

  return (
    <>
      <div className="cart-item">
        <div className="cart-item-data">
          <div className="cart-item-title">
            <p>{count > 1 ? count + ' x ' + title : title}</p>
          </div>
          <ul className="cart-list">
            <li className="cart-list-item">
              <p className="cart-list-item--label">{(count > 1 ? count + ' x ' : '') + option.label}</p>
              <p>{price}</p>
            </li>
            {sections &&
              sections.flat().map((section, idx) => (
                <li key={'Item-' + id + '-section' + idx} className="cart-list-item">
                  <p className="cart-list-item--label">
                    {(section.count || count > 1 ? section.count * count + ' x ' : '') + section.label}
                  </p>
                  {section.price ? <p>{formatCurrency(section.price * section.count * count)}</p> : null}
                </li>
              ))}
            {suggestions &&
              suggestions.flat().map((suggestion, idx) => (
                <li key={'Item-' + id + '-suggestion' + idx} className="cart-list-item">
                  <p className="cart-list-item--label">
                    {(suggestion.count ? suggestion.count + ' x ' : '') + suggestion.label}
                  </p>
                  {suggestion.price ? <p>{formatCurrency(suggestion.price * suggestion.count)}</p> : null}
                </li>
              ))}
          </ul>
        </div>
        <div className="cart-item-buttons">
          <RoundedRectangleButton className="cart-button-edit" icon="uil:pen" onClick={handleEdit} />
          <RoundedRectangleButton className="cart-button-delete" icon="uil:trash-alt" onClick={handleDelete} />
        </div>
      </div>
      {directions && (
        <div className="cart-directions">
          <Accordion title="Mostrar indicaciones">
            <p className="default-text">{directions}</p>
          </Accordion>
        </div>
      )}
    </>
  );
}

export default memo(CartItem);

CartItem.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  directions: PropTypes.string,
  option: PropTypes.exact({
    label: PropTypes.string.isRequired,
    price: PropTypes.number,
    isDisabled: PropTypes.bool.isRequired,
    count: PropTypes.number,
  }).isRequired,
  sections: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      price: PropTypes.number,
      isDisabled: PropTypes.bool.isRequired,
      count: PropTypes.number,
    }),
  ),
  suggestions: PropTypes.arrayOf(
    PropTypes.exact({
      label: PropTypes.string.isRequired,
      price: PropTypes.number,
      isDisabled: PropTypes.bool.isRequired,
      count: PropTypes.number,
    }),
  ),
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};
