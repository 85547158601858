import React from 'react';
import logo from 'Images/logo.svg';
import 'Styles/BarFooter.scss';

export const BarFooter = () => {
  return (
    <div className="app-footer">
      <img src={logo} alt="logo footer" />
      <span>Pedidos a un click</span>
    </div>
  );
};
