import { useState, useEffect } from 'react';
import EventsManager from 'Controllers/EventsManager';
import WebSocketServices from '../services/WebSocketServices';

const MEDIA_QUERY = 992;

export function useDesktopMode() {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= MEDIA_QUERY);
  useEffect(() => {
    const eventsManager = EventsManager.giveEventsManager();
    let token;
    if (eventsManager) {
      token = eventsManager.subscribe('onWindowsResize', () => {
        if (window.innerWidth < MEDIA_QUERY) setIsDesktop(false);
        else setIsDesktop(true);
      });
    }
    return () => {
      if (eventsManager && token) eventsManager.unsubscribe(token);
    };
  });

  return isDesktop;
}

export const useWebSocket = (commerceId, phoneNumber) => {
  useEffect(() => {
    WebSocketServices.init(commerceId, phoneNumber);
    return () => {
      WebSocketServices.close();
    };
  }, [commerceId]);
};
