import React, { memo } from 'react';
import PropTypes from 'prop-types';
import LineCheckBox from 'Elements/LineCheckBox';
import StringInput from 'Elements/StringInput';
import { BankingInformation } from 'Components/BankingInformation';
import { ALLOWED_PAYMENT_METHODS_MAP, ALL_ALLOWED_PAYMENT_METHODS } from '../../utils/constants';

const Section = ({ children, style }) => {
  return <section style={{ width: '100%', padding: '0 25px', ...style }}>{children}</section>;
};

function MethodPay({
  payMethods,
  currentPayMethod,
  onValueChange,
  error = false,
  total,
  bankingInformation,
  allowedPaymentMethods,
}) {
  return (
    <>
      {payMethods.cash && allowedPaymentMethods.includes(ALLOWED_PAYMENT_METHODS_MAP.Efectivo.key) ? (
        <LineCheckBox
          text="Efectivo"
          type="radio"
          name="MethodPay"
          value={currentPayMethod.type === 'cash' ? 1 : 0}
          onChange={(checked) => {
            onValueChange({ ...currentPayMethod, type: checked ? 'cash' : null });
          }}
        />
      ) : null}
      {currentPayMethod.type === 'cash' && allowedPaymentMethods.includes(ALLOWED_PAYMENT_METHODS_MAP.Efectivo.key) ? (
        <Section style={{ marginBottom: '5px' }}>
          <StringInput
            error={error}
            type="number"
            value={currentPayMethod.value ? currentPayMethod.value.toString() : ''}
            placeHolder="¿Con cuanto pagas?"
            onValueChange={(value) => {
              onValueChange({ ...currentPayMethod, value: parseFloat(value) || 0 });
            }}
          />
        </Section>
      ) : null}
      {payMethods.card && allowedPaymentMethods.includes(ALLOWED_PAYMENT_METHODS_MAP.Tarjeta.key) ? (
        <LineCheckBox
          text="Tarjeta (Terminal)"
          type="radio"
          name="MethodPay"
          value={currentPayMethod.type === 'card' ? 1 : 0}
          onChange={(checked) => {
            onValueChange({ ...currentPayMethod, type: checked ? 'card' : null });
          }}
        />
      ) : null}
      {payMethods.online &&
      allowedPaymentMethods.includes(ALLOWED_PAYMENT_METHODS_MAP.enlace_pago.key) &&
      (!payMethods.payment_provider ||
        !allowedPaymentMethods.includes(ALLOWED_PAYMENT_METHODS_MAP.payment_provider.key)) ? (
        <LineCheckBox
          text={total >= 50 ? 'Pagar en línea' : 'Pagar en línea (mínimo $50)'}
          type="radio"
          name="MethodPay"
          disabled={total < 50}
          value={currentPayMethod.type === 'online' ? 1 : 0}
          onChange={(checked) => {
            onValueChange({ ...currentPayMethod, type: checked ? 'online' : null });
          }}
        />
      ) : null}
      {payMethods.payment_provider &&
      allowedPaymentMethods.includes(ALLOWED_PAYMENT_METHODS_MAP.payment_provider.key) ? (
        <LineCheckBox
          text={total >= 50 ? 'Pagar en línea con Tarjeta' : 'Pagar en línea con Tarjeta (mínimo $50)'}
          type="radio"
          name="MethodPay"
          disabled={total < 50}
          value={currentPayMethod.type === 'payment_provider' ? 1 : 0}
          onChange={(checked) => {
            onValueChange({ ...currentPayMethod, type: checked ? 'payment_provider' : null });
          }}
        />
      ) : null}
      {payMethods.transfer && allowedPaymentMethods.includes(ALLOWED_PAYMENT_METHODS_MAP.Transferencia.key) ? (
        <LineCheckBox
          text="Transferencia"
          type="radio"
          name="MethodPay"
          value={currentPayMethod.type === 'transfer' ? 1 : 0}
          onChange={(checked) => {
            onValueChange({ ...currentPayMethod, type: checked ? 'transfer' : null });
          }}
        />
      ) : null}
      {currentPayMethod.type === 'transfer' &&
      bankingInformation?.clabe &&
      allowedPaymentMethods.includes(ALLOWED_PAYMENT_METHODS_MAP.Transferencia.key) ? (
        <Section style={{ marginTop: '2px' }}>
          <BankingInformation
            name={bankingInformation?.name}
            bank={bankingInformation?.bank}
            clabe={bankingInformation.clabe}
          />
        </Section>
      ) : null}
    </>
  );
}

export default memo(MethodPay);

MethodPay.propTypes = {
  currentPayMethod: PropTypes.exact({ type: PropTypes.string, value: PropTypes.number }).isRequired,
  onValueChange: PropTypes.func.isRequired,
  allowedPaymentMethods: PropTypes.arrayOf(PropTypes.oneOf(ALL_ALLOWED_PAYMENT_METHODS)).isRequired,
  error: PropTypes.bool,
  total: PropTypes.number,
  payMethods: PropTypes.exact({
    card: PropTypes.bool,
    transfer: PropTypes.bool,
    cash: PropTypes.bool,
    online: PropTypes.bool,
    payment_provider: PropTypes.bool,
  }).isRequired,
  bankingInformation: PropTypes.exact({
    name: PropTypes.string,
    bank: PropTypes.string,
    clabe: PropTypes.string,
  }),
};

Section.propTypes = {
  children: PropTypes.node,
  style: PropTypes.object,
};
