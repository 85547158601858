/* global REACT_APP_PUBLIC_ENABLE_TRACKING */
/* global REACT_APP_PUBLIC_ENABLE_LOG */
/* global REACT_APP_PUBLIC_SENTRY_ENVIRONMENT */
/* global REACT_APP_PUBLIC_SENTRY_DNS */
/* global REACT_APP_PUBLIC_POSTHOG_HOST */
/* global REACT_APP_PUBLIC_POSTHOG_KEY */

import React from 'react';
import { createRoot } from 'react-dom/client';
import smoothscroll from 'smoothscroll-polyfill';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';
import { PostHogProvider } from 'posthog-js/react';
import App from './App';
import Directory from './components/directory/Directory';

smoothscroll.polyfill();
const container = document.getElementById('root');
const root = createRoot(container);
const directoryPathName = window.location.pathname.split('/directorio/').pop();

if (REACT_APP_PUBLIC_ENABLE_LOG) {
  const isProd = REACT_APP_PUBLIC_SENTRY_ENVIRONMENT === 'production';
  const tracesSampleRate = isProd ? 0.2 : 0.7;

  Sentry.init({
    dsn: REACT_APP_PUBLIC_SENTRY_DNS,
    debug: false,
    environment: REACT_APP_PUBLIC_SENTRY_ENVIRONMENT,
    integrations: [
      new Sentry.BrowserTracing({
        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ['127.0.0.1', 'localhost', /^https:\/\/amazonaws\.com\//],
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: tracesSampleRate,
    // Session Replay
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

const PostHogHOC = ({ children }) => {
  return !REACT_APP_PUBLIC_ENABLE_TRACKING ? (
    children
  ) : (
    <PostHogProvider
      apiKey={REACT_APP_PUBLIC_POSTHOG_KEY}
      options={{
        api_host: REACT_APP_PUBLIC_POSTHOG_HOST,
        opt_in_site_apps: true,
      }}
    >
      {children}
    </PostHogProvider>
  );
};

PostHogHOC.propTypes = {
  children: PropTypes.node.isRequired
};

root.render(
  <React.StrictMode>
    <PostHogHOC>
      {window.location.pathname.includes('/directorio/') ? (
        <Directory directoryPathName={directoryPathName} />
      ) : (
        <App />
      )}
    </PostHogHOC>
  </React.StrictMode>
);
