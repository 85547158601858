import React, { memo } from 'react';
import PropTypes from 'prop-types';
import 'Styles/StatusLabel.scss';

function StatusLabel({ isOpen }) {
  return (
    <div className="status_label">
      <div className={'status_label-status ' + (isOpen ? 'open' : 'closed')} />
      <span className="status_label-label">{isOpen ? 'Abierto' : 'Cerrado'}</span>
    </div>
  );
}

export default memo(StatusLabel);

StatusLabel.propTypes = {
  isOpen: PropTypes.bool.isRequired,
};
