let currentToken = 0;
class EventsManagerInstance {
  constructor() {
    this.events = [];
  }

  publish(event, data) {
    let eventObj = this.events.find((evt) => evt.id === event);
    if (eventObj) {
      eventObj.subscribers.forEach((sub) => sub.callback(data));
    }
  }

  subscribe(event, callback) {
    let eventObj = this.events.find((evt) => evt.id === event);
    if (!eventObj) {
      eventObj = { id: event, subscribers: [] };
      this.events.push(eventObj);
    }
    currentToken++;
    eventObj.subscribers.push({ token: currentToken, callback: callback });
    return currentToken;
  }

  unsubscribe(token) {
    return this.events.some((evt) => {
      let index = evt.subscribers.findIndex((sub) => sub.token === token);
      if (index >= 0) {
        evt.subscribers.splice(index, 1);
        return true;
      }
      return false;
    });
  }

  clear() {
    currentToken = 0;
    this.events = null;
  }
}

let instance;
export default class EventsManager {
  static getEventsManager() {
    if (!instance) {
      let controller = new EventsManagerInstance();
      instance = Object.freeze({
        publish: (event, data) => {
          if (controller) return controller.publish(event, data);
        },
        subscribe: (event, callback) => {
          if (controller) return controller.subscribe(event, callback);
        },
        unsubscribe: (token) => {
          if (controller) return controller.unsubscribe(token);
        },
        clear: () => {
          if (controller) controller.clear();
          controller = null;
        },
      });
    }
    return instance;
  }
  static giveEventsManager() {
    return instance;
  }
  static unreferenceEventsManager() {
    if (instance) instance.clear();
    instance = null;
  }
}
