import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import 'Styles/BarButton.scss';
import { OrderInProgress } from './OrderInProgress';
import { BarFooter } from './BarFooter';
import { useDesktopMode } from '../../hooks/hooks';

function BarButton({ onClick, children, disabled = false, activeOrders = [] }) {
  const buttonRef = useRef(null);
  const isDesktop = useDesktopMode();

  useEffect(() => {
    let removeAnimation = () => {
      if (buttonRef.current) {
        buttonRef.current.classList.remove('animateDisabled');
      }
    };
    if (buttonRef.current) {
      buttonRef.current.addEventListener('animationend', removeAnimation);
    }
    return () => {
      if (buttonRef.current) {
        buttonRef.current.removeEventListener('animationend', removeAnimation);
      }
    };
  }, []);

  const handleOnClick = useCallback(() => {
    if (disabled && buttonRef.current) buttonRef.current.classList.add('animateDisabled');
    onClick();
  }, [onClick]);

  return (
    <div className="bar_button-main_div">
      {children && (
        <button ref={buttonRef} className={'bar_button' + (disabled ? ' disabled' : '')} onClick={handleOnClick}>
          {children}
        </button>
      )}
      {!isDesktop && activeOrders.length > 0 ? <OrderInProgress /> : null}
      <BarFooter />
    </div>
  );
}

export default BarButton;

BarButton.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  activeOrders: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.string.isRequired,
      orderId: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      deliveryMethod: PropTypes.string.isRequired,
      deliveryType: PropTypes.string.isRequired,
      hour: PropTypes.string.isRequired,
      deliveryDriverInfo: PropTypes.exact({
        name: PropTypes.string.isRequired,
        phone: PropTypes.number.isRequired,
        image: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
      }),
      deliveryTrackingUrl: PropTypes.string,
      paymentMethod: PropTypes.string,
      orderTotal: PropTypes.number,
    }),
  ),
};
