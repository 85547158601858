import { useState } from 'react';
import { stripeCheckout } from './stripe/stripeCheckout';

export const usePaymentProviders = () => {
  const [stripe, setStripe] = useState(null);
  const [stripeElements, setStripeElements] = useState(null);

  const doPayment = async ({ provider_code }) => {
    if (provider_code === 'stripe') {
      const response = await stripeCheckout({ stripe, stripeElements });
      return response;
    } else if (provider_code === 'mercadoPago') {
      return true;
    } else if (provider_code === 'clip') {
      return true;
    }

    return 'Provider checkout implementation not found';
  };

  return {
    stripe,
    stripeElements,
    setStripe,
    setStripeElements,
    doPayment,
  };
};
