import React, { memo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import '../assets/css/ProductCard.scss';
import { Icon } from '@iconify/react';
import { formatCurrency } from '../functions/money';

function ProductCard({ title, description, price, imageUrl, onClick }) {
  const [imageError, setImageError] = useState(false);

  const handleImageError = useCallback(() => {
    setImageError(true);
  }, []);

  return (
    <div className="product_card" onClick={onClick}>
      {imageUrl && !imageError ? (
        <img className="product_card-image" alt="Producto" src={imageUrl} onError={handleImageError} />
      ) : (
        <div className="product_card-image default">
          <Icon icon="uil:camera" />
          <span>Producto Sin Imagen</span>
        </div>
      )}
      <div className="product_card-item">
        <span className="product_card-item-title">{title}</span>
        <span className="product_card-item-description">{description}</span>
        <span className="product_card-item-price">{formatCurrency(price)}</span>
      </div>
    </div>
  );
}

export default memo(ProductCard);

ProductCard.propTypes = {
  title: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  description: PropTypes.string,
  imageUrl: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};
