import React from 'react';
import PropTypes from 'prop-types';
import '../assets/css/RadioButtons.scss';

function RadioButtons({ items, current, name, onChange }) {
  return (
    <div
      name={name}
      className="radio_button-main_div"
      onChange={(event) => {
        onChange(parseInt(event.target.id.split('radioButton' + name)[1]));
      }}
    >
      {items.map((item, idx) => {
        return (
          <div className="radio_button" key={'radioButton' + name + idx}>
            <input name={name} type="radio" id={'radioButton' + name + idx} defaultChecked={idx === current} />
            <label htmlFor={'radioButton' + name + idx}>{item}</label>
          </div>
        );
      })}
    </div>
  );
}

export default RadioButtons;

RadioButtons.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string),
  current: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
