import React, { useEffect, useState, useRef } from 'react';
import ApiServices from 'ApiServices/ApiServices';
import 'Styles/BranchOfficesList.scss';
import BranchOfficeItem from './BranchOfficeItem';
import PropTypes from 'prop-types';
import logo from 'Images/logo_with_slogan.svg';
import Page404 from 'Pages/Page404';
import logoPlick from 'Images/logo.svg';

function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
  const R = 6371;
  const dLat = ((lat2 - lat1) * Math.PI) / 180;
  const dLon = ((lon2 - lon1) * Math.PI) / 180;
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos((lat1 * Math.PI) / 180) * Math.cos((lat2 * Math.PI) / 180) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c;
}

function BranchOfficesList({ pathName }) {
  const [branchOfficesData, setBranchOfficesData] = useState({ comercios: [], directorio: {} });
  const [isLoading, setLoading] = useState(true);
  const [isNotFount, setNotFound] = useState(false);
  const loadingPageRef = useRef(null);
  const [userLocation, setUserLocation] = useState(null);

  const handleBranchOffice = (idpath) => {
    window.location.assign(`${window.location.origin}/${idpath}`);
  };

  useEffect(function () {
    (async function () {
      try {
        const data = await ApiServices.getBranchOfficesData(pathName);
        if (!data || !data.directorio) setNotFound(true);
        setBranchOfficesData(data);
        loadingPageRef.current.style.opacity = 0;
        loadingPageRef.current.addEventListener(
          'transitionend',
          () => {
            setLoading(false);
          },
          { once: true },
        );
      } catch (error) {
        setNotFound(true);
        window.console.error(error);
        if (loadingPageRef.current) {
          loadingPageRef.current.style.opacity = 0;
          loadingPageRef.current.addEventListener(
            'transitionend',
            () => {
              setLoading(false);
            },
            { once: true },
          );
        }
      }
    })();
  }, []);

  useEffect(() => {
    if (navigator?.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setUserLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        },
        () => {
          setUserLocation(null);
        },
      );
    }
  }, []);

  useEffect(() => {
    if (userLocation && branchOfficesData.comercios.length > 0) {
      const updatedComercios = branchOfficesData.comercios
        .map((item) => ({
          ...item,
          toDistance: getDistanceFromLatLonInKm(
            userLocation.latitude,
            userLocation.longitude,
            item?.geolocalizacion?.latitud || 35.6979235,
            item?.geolocalizacion?.longitud || 139.7731759,
          ),
        }))
        .sort((a, b) => a.toDistance - b.toDistance);

      setBranchOfficesData({ ...branchOfficesData, comercios: updatedComercios });
    }
  }, [userLocation, branchOfficesData]);

  return (
    <>
      {isLoading && (
        <div ref={loadingPageRef} className="loading_page">
          <img src={logo} alt="Plick Logo" />
        </div>
      )}
      {isNotFount ? (
        <Page404 />
      ) : (
        <div className="branch_offices_screen">
          <div className="desktop_header">
            <div className="desktop_header--logo">
              <img src={logoPlick} alt="logo plick" height="25px" />
              <span>
                Pedidos a un <mark>click.</mark>
              </span>
            </div>
          </div>

          <div className="branch_offices_header-list">
            <div className="branch_offices_logo">
              <img src={branchOfficesData.directorio.logotipo} alt="logo" />
            </div>
            <p className="branch_offices_text">Escoge tu sucursal</p>
          </div>
          <div className="branch_offices_list">
            {branchOfficesData.comercios.map((item) => (
              <BranchOfficeItem
                key={item.id_path}
                nombre={item.nombre}
                abierto={item.abierto}
                horarios={item.horarios_dia}
                tags={item.tags}
                tiempomin={item.tiempo_estimado_servicio_domicilio_min}
                tiempomax={item.tiempo_estimado_servicio_domicilio_max}
                direccion={item.direccion || item?.address?.fullAddress}
                cover={item.cover}
                onClick={() => handleBranchOffice(item.id_path)}
                toDistance={
                  userLocation &&
                  item?.geolocalizacion?.latitud &&
                  item?.geolocalizacion?.longitud &&
                  (item?.toDistance || item?.toDistance === 0)
                    ? Number(item.toDistance)
                    : null
                }
              />
            ))}
          </div>

          <div className="bottom-bar">
            <div className="bottom-bar-footer">
              <img src={logoPlick} alt="logo footer" />
              <span>Pedidos a un click</span>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default BranchOfficesList;

BranchOfficesList.propTypes = {
  pathName: PropTypes.string.isRequired,
};
