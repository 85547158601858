export const statusDetails = {
  'AP-PAI01': 'Pagado',
  'AP-REF01': 'Parcialmente reembolsado',
  'RE-REF01': 'Reembolsado',
  'AU-CAP01': 'Captura pendiente',
  'CA-AUT01': 'Cancelado',
  'CA-MAN01': 'Cancelado',
  'CA-MER01': 'Cancelado',
  'RE-BIN01': 'Rechazado',
  'RE-ERI01': 'Rechazado',
  'RE-ERI02': 'Rechazado',
  'RE-3DS01': 'Autenticación 3DS fallida',
  'RE-CHI01': 'Rechazado',
  'RE-ISS01': 'Fondos insuficientes',
  'RE-ISS02': 'No autorizado',
  'RE-ISS03': 'Tarjeta restringida',
  'RE-ISS04': 'Reservado para uso privado',
  'RE-ISS05': 'Transacción no permitida para el titular de la tarjeta',
  'RE-ISS06': 'Recoger tarjeta',
  'RE-ISS07': 'Tarjeta vencida',
  'RE-ISS08': 'Excede el límite de retiro',
  'RE-ISS09': 'PIN no válido (una vez)',
  'RE-ISS10': 'Número permitido de intentos de PIN excedido',
  'RE-ISS11': 'Consultar con el emisor de la tarjeta',
  'RE-ISS12': 'Cantidad no válida',
  'RE-ISS13': 'Destino no disponible (emisor fuera de línea)',
  'RE-ISS14': 'Emisor o switch inoperativo',
  'RE-ISS15': 'Fallback de Visa/mc',
  'RE-ISS16': 'Número de tarjeta no válido (no existe ese número)',
  'RE-ISS17': 'Comerciante no válido',
  'RE-ISS18': 'Transacción no válida',
  'RE-ISS19': 'Rechazado',
  'RE-ISS20': 'Rechazado',
  'RE-ISS99': 'No se pudo procesar el pago, intente nuevamente',
  'PE-EMV01': 'Esperando emv',
  'PE-SIG01': 'Esperando firma',
  'PE-3DS01': 'Esperando 3DS',
  'PE-TIC01': 'Esperando pago',
};
