import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import 'Styles/FlatButton.scss';

function FlatButton({ icon, className, onClick }) {
  return (
    <button onClick={onClick} className={'flat_button-main_div ' + (className || '')}>
      <Icon icon={icon} />
    </button>
  );
}

export default FlatButton;

FlatButton.propTypes = {
  icon: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
};
