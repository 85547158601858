export const stripeCheckout = async ({ stripe, stripeElements }) => {
  if (!stripe || !stripeElements) {
    return 'No se pudo procesar el pago, intente nuevamente';
  }

  const res = await stripe.confirmPayment({
    elements: stripeElements,
    redirect: 'if_required',
  });

  if (res.error) {
    return res?.error?.message || 'Error al procesar el pago';
  }
};
