import { useEffect, useState } from 'react';

const url = 'https://d1p3k3qn1rvfpv.cloudfront.net/js/clip-sdk.js';

export const useLoadClip = () => {
  const [isJSLoaded, setIsJSLoaded] = useState(false);

  useEffect(() => {
    if (document.querySelector(`script[src="${url}"]`)) {
      setIsJSLoaded(true);
      return;
    }

    const loadClipJS = async () => {
      const script = document.createElement('script');
      script.src = url;
      script.async = true;
      document.body.appendChild(script);

      script.onload = async () => {
        setIsJSLoaded(true);
      };
    };

    loadClipJS();
  }, []);

  return { isJSLoaded };
};
