import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import FlatButton from 'Elements/FlatButton';

function Search({ value, onSearchChange }) {
  const searchInputRef = useRef(null);

  return (
    <>
      <Icon icon="uil:search" />
      <input
        ref={searchInputRef}
        placeholder="Buscar"
        maxLength="40"
        onChange={(evnt) => {
          onSearchChange(evnt.target.value.toLowerCase());
        }}
      />
      {value && (
        <FlatButton
          icon="eva:close-outline"
          onClick={() => {
            if (searchInputRef.current) searchInputRef.current.value = '';
            onSearchChange('');
          }}
        />
      )}
    </>
  );
}

export default Search;

Search.propTypes = {
  value: PropTypes.string.isRequired,
  onSearchChange: PropTypes.func.isRequired,
};
