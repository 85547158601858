import React, { memo } from 'react';
import PropTypes from 'prop-types';
import 'Styles/BusinessLocation.scss';
import { Icon } from '@iconify/react';

function BusinessLocation({ location, mapsUrl, commerceLocation }) {
  const URL =
    commerceLocation?.latitud && commerceLocation?.longitud
      ? `https://www.google.com/maps/search/?api=1&query=${commerceLocation.latitud},${commerceLocation.longitud}`
      : mapsUrl;
  return (
    <div className="box-location">
      <div className="box-location_address">
        <p>{location}</p>
      </div>
      {URL && (
        <a className="buttonLoc" href={URL} target="_blank" rel="noreferrer">
          Ver dirección en mapa
          <Icon className="buttonLoc-icon" icon="el:map-marker" />
        </a>
      )}
    </div>
  );
}

export default memo(BusinessLocation);

BusinessLocation.propTypes = {
  location: PropTypes.string.isRequired,
  mapsUrl: PropTypes.string,
  commerceLocation: PropTypes.exact({
    latitud: PropTypes.number,
    longitud: PropTypes.number,
  }),
};
