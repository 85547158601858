import './clipButton.scss';
import React from 'react';

type ClipButtonProps = {
  total: number;
  onClick: () => void;
};

export const ClipButton = ({ total, onClick }: ClipButtonProps) => {
  return (
    <button
      id="clipPayBtn"
      type="button"
      onClick={onClick}
      style={{
        backgroundColor: '#fc4c02',
        color: '#fff',
        boxShadow: '0 6px 12px rgba(252,76,2,.2)',
        fontWeight: 600,
        fontSize: '15px',
        lineHeight: '1.75',
        padding: '6px 16px',
        borderRadius: '8px',
        border: 'none',
        height: '48px',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '5px',
      }}
    >
      Pagar ${total.toFixed(2)} MXN con
      <img
        src="https://sdk.clip.mx/assets/images/buyer-checkout/logos/buttonLogoClip.svg"
        alt="Clip"
        style={{ width: '28px', height: '28px' }}
      />
    </button>
  );
};
