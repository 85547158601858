import React, { memo, useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import FlatButton from 'Elements/FlatButton';
import 'Styles/Dialog.scss';
import EventsManager from 'Controllers/EventsManager';

function Dialog({ id, children, preventClose, fullWidth = true }) {
  const dialogDiv = useRef(null);
  const handleBackClick = useCallback((data) => {
    if (!data.id || data.id === id) {
      dialogDiv.current.classList.remove('openDialog');
      dialogDiv.current.classList.add('closeDialog');
      dialogDiv.current.addEventListener(
        'animationend',
        () => {
          let eventsManager = EventsManager.giveEventsManager();
          if (eventsManager) eventsManager.publish('onPageExit');
        },
        { once: true },
      );
    }
  }, []);

  useEffect(() => {
    let token;
    let eventsManager = EventsManager.giveEventsManager();
    if (eventsManager) token = eventsManager.subscribe('onPageExitRequest', handleBackClick);
    return () => {
      if (eventsManager) token = eventsManager.unsubscribe(token);
    };
  }, []);

  return (
    <div ref={dialogDiv} className="dialog openDialog">
      <div className={`dialog-wrapper ${!fullWidth && 'width-auto'}`}>
        <div className="dialog-content">{children}</div>
        {!preventClose && (
          <FlatButton icon="eva:close-outline" className="dialog-close_button" onClick={handleBackClick}></FlatButton>
        )}
      </div>
    </div>
  );
}

export default memo(Dialog);

Dialog.propTypes = {
  id: PropTypes.string,
  preventClose: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  fullWidth: PropTypes.bool,
};
