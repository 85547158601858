declare global {
  interface Window {
    ClipSDK: any;
  }
}

import { useEffect, useState } from 'react';

type UseCreateFormProps = {
  isJSLoaded: boolean;
  formID: string;
  key: string;
};

export const useCreateForm = ({ isJSLoaded, formID, key }: UseCreateFormProps) => {
  const [isFormCreated, setIsFormCreated] = useState(false);
  const [isError, setIsError] = useState(false);
  const [clipCard, setClipCard] = useState(null);

  useEffect(() => {
    if (!isJSLoaded || !formID || !key) return;

    const createForm = async () => {
      setClipCard(null);
      setIsFormCreated(false);

      try {
        await new Promise((resolve) => {
          const interval = setInterval(() => {
            if (window.ClipSDK) {
              clearInterval(interval);
              resolve(true);
            }
          }, 100);
        });

        const clip = new window.ClipSDK(key);
        const card = clip.element.create('Card', {
          theme: 'default',
          locale: 'es',
        });

        card.mount(formID);
        setClipCard(card);
        await new Promise((resolve) => setTimeout(resolve, 2000));
        setIsFormCreated(true);
      } catch (error) {
        setIsFormCreated(false);
        setIsError(true);
        setClipCard(null);
        console.error(error);
      }
    };

    createForm();
  }, [isJSLoaded, formID, key]);

  return {
    isFormCreated,
    isError,
    clipCard,
  };
};
