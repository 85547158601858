import React from 'react';
import EventsManager from 'Controllers/EventsManager';
import { Icon } from '@iconify/react';
import 'Styles/OrderInProgress.scss';

export const OrderInProgress = () => {
  return (
    <div
      className="order-in-progress"
      onClick={() => {
        let eventsManager = EventsManager.giveEventsManager();
        if (eventsManager) {
          eventsManager.publish('onOrderStatusPageRequest');
          let token = eventsManager.subscribe('onPageExit', (result) => {
            eventsManager.unsubscribe(token);
            if (result) {
              // exit from map dialog
            }
          });
        }
      }}
    >
      <p>Pedido en proceso. Ver estatus</p>
      <div className="order-in-progress-icon">
        <Icon icon="dashicons:food" color="#2fc12f" width="28px" height="28px" />
      </div>
    </div>
  );
};
