import { useState } from 'react';
// @ts-ignore
import EventsManager from '../../../controllers/EventsManager';
// @ts-ignore
import ApiServices from '../../../services/ApiServices';
import { statusDetails } from './statusDetails';

type HandleCheckoutProps = {
  amount: number;
  createOrder: () => void;
  commerceId: string;
  providerCode: string;
  phone: string;
  clipCard: any;
  customerOnlyPhone: string;
};

export const useClipCheckout = () => {
  const [isCheckingOut, setIsCheckingOut] = useState(false);
  const [iframeUrl, setIframeUrl] = useState<string | null>(null);

  const handleCheckout = async ({
    amount,
    createOrder,
    commerceId,
    providerCode,
    phone,
    customerOnlyPhone,
    clipCard,
  }: HandleCheckoutProps) => {
    const errorsMap = {
      CL2200: 'Datos de la tarjeta inválidos',
      CL2290: 'Datos de la tarjeta inválidos',
      AI1300: 'Tarjeta no soportada, intenta con otra',
    };
    const genericError = 'No se pudo procesar el pago, intente nuevamente';
    let cardToken;

    try {
      setIsCheckingOut(true);
      cardToken = await clipCard.cardToken();
    } catch (error: any) {
      const detail = errorsMap[error?.code as keyof typeof errorsMap] || genericError;
      const eventsManager = EventsManager.giveEventsManager();
      if (eventsManager) {
        eventsManager.publish('displayNotification', {
          type: 'error',
          message: detail,
        });
      }

      setIsCheckingOut(false);
      return;
    }

    try {
      if (!clipCard) {
        throw new Error('Clip card is not defined');
      }

      const payload = {
        amount,
        currency: 'MXN',
        description: 'Pedido por Plick',
        payment_method: {
          token: cardToken.id,
        },
        customer: {
          email: 'no-reply@plick.com.mx',
          phone: customerOnlyPhone,
        },
        address: {
          postal_code: '86077',
          street: 'Iguala',
          number: '218',
        },
      };

      const response = await ApiServices.createPayment({
        commerceId,
        amount,
        phone,
        providerCode,
        paymentData: payload,
      });

      if (!response?.id || !response?.status || response.status !== 'approved') {
        if (response?.pending_action?.type === 'open_modal' && response?.pending_action?.url) {
          setIframeUrl(response.pending_action.url);
          setIsCheckingOut(false);
          return;
        }

        const error = statusDetails[response?.status_detail?.code as keyof typeof statusDetails] || genericError;

        const eventsManager = EventsManager.giveEventsManager();
        if (eventsManager) {
          eventsManager.publish('displayNotification', {
            type: 'error',
            message: error,
          });
        }

        setIsCheckingOut(false);
        return;
      }
    } catch (error) {
      const eventsManager = EventsManager.giveEventsManager();
      if (eventsManager) {
        eventsManager.publish('displayNotification', {
          type: 'error',
          message: genericError,
        });
      }

      setIsCheckingOut(false);
      return;
    }

    createOrder();
  };

  return {
    isCheckingOut,
    handleCheckout,
    iframeUrl,
    setIsCheckingOut,
    setIframeUrl,
  };
};
