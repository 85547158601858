import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Page from 'Elements/Page';
import EventsManager from 'Controllers/EventsManager';
import BarButton from 'Elements/BarButton';
import ApiServices from 'ApiServices/ApiServices';
import { Icon } from '@iconify/react';

import '../assets/css/OnlinePaymentPage.scss';

function OnlinePaymentPage({ data }) {
  const [displayValidationButton, setDisplayValidationButton] = useState(false);
  const [isProcessing, setProcessing] = useState(false);

  useEffect(() => {
    const eventsManager = EventsManager.giveEventsManager();
    let token;
    if (eventsManager)
      token = eventsManager.subscribe('onWebsocketSubscriptionFailed', ({ eventName }) => {
        if (eventName === 'orders') setDisplayValidationButton(true);
      });

    return () => {
      if (eventsManager && token) {
        eventsManager.unsubscribe(token);
      }
    };
  }, []);
  return (
    <Page id="OnlinePayment_Page">
      <div className="online_payment_page">
        <p className="online_payment_page-title">Pago en línea</p>
        <div className="payment-iframe-banner">
          <div className="payment-iframe-progress">
            <Icon icon="line-md:loading-twotone-loop" />
          </div>
          <span>
            Ir atrás sólo si desea <b>cancelar</b> la transacción
          </span>
        </div>
        <iframe
          src={data.url}
          className="payment-iframe"
          style={displayValidationButton ? { paddingBottom: 90 } : undefined}
          seamless
        />
        {displayValidationButton && (
          <BarButton
            disabled={isProcessing}
            onClick={async () => {
              setProcessing(true);
              try {
                const result = await ApiServices.checkPaymentStatus(data.orderId);
                if (result && result.estatus_pago === 'pagado') {
                  const eventsManager = EventsManager.giveEventsManager();
                  if (eventsManager)
                    eventsManager.publish('onWebsocketMessage', {
                      eventId: 'changeOrderStatus',
                      status: 'pagado',
                      orderId: data.orderId,
                    });
                } else {
                  const eventsManager = EventsManager.giveEventsManager();
                  if (eventsManager)
                    eventsManager.publish('displayNotification', {
                      type: 'error',
                      message: 'El pago no ha sido registrado',
                    });
                  setProcessing(false);
                }
              } catch (error) {
                window.console.error(error);
              }
            }}
          >
            <div className="product_page-bar_button">
              <Icon icon={isProcessing ? 'line-md:loading-twotone-loop' : 'akar-icons:check'} />
              <span>Verificar Pago</span>
            </div>
          </BarButton>
        )}
      </div>
    </Page>
  );
}

export default OnlinePaymentPage;

OnlinePaymentPage.propTypes = {
  data: PropTypes.exact({
    url: PropTypes.string,
    orderId: PropTypes.string,
  }),
};
