/* global WEBSOCKET_URL */
import EventsManager from 'Controllers/EventsManager';

let _commerceId;

export default class WebSocketServices {
  static init(commerceId, phoneNumber) {
    _commerceId = commerceId;
    if (commerceId && !this.instance) {
      const connectWebsocket = () => {
        this.instance = new WebSocket(WEBSOCKET_URL);
        let websocketKeepAliveInterval;
        this.instance.addEventListener('open', () => {
          this.instance.send(
            JSON.stringify({
              action: 'subscribe',
              message: JSON.stringify(['user', commerceId, `orders_${commerceId}`, `orders_${phoneNumber}`]),
            }),
          );
          websocketKeepAliveInterval = setInterval(() => {
            this.instance.send(JSON.stringify({ action: 'checkConnection', message: 'testConnection' }));
          }, 9.5 * 60 * 1000);
        });
        this.instance.addEventListener('message', (ev) => {
          if (ev.data) {
            try {
              let data = JSON.parse(ev.data);
              if (data && data.eventId) {
                const eventsManager = EventsManager.giveEventsManager();
                if (eventsManager) eventsManager.publish('onWebsocketMessage', data);
              }
            } catch (error) {
              window.console.error('Error on websocket:', error);
            }
          }
        });
        this.instance.addEventListener('close', (ev) => {
          if (websocketKeepAliveInterval) {
            clearInterval(websocketKeepAliveInterval);
            websocketKeepAliveInterval = null;
            this.instance = null;
          }
          if (ev.code !== 1000) setTimeout(connectWebsocket);
        });
      };
      connectWebsocket();
    }
  }

  static subscribe(event) {
    const eventsManager = EventsManager.giveEventsManager();
    if (!this.instance || !_commerceId)
      return eventsManager.publish('onWebsocketSubscriptionFailed', { eventName: event });
    const eventId = `${event}_${_commerceId}`;
    let tokenTO;
    const token = eventsManager.subscribe('onWebsocketMessage', (socketData) => {
      if (socketData && socketData.eventId === 'newSubscription' && socketData.channelIds.includes(eventId)) {
        clearTimeout(tokenTO);
        eventsManager.unsubscribe(token);
      }
    });
    tokenTO = setTimeout(() => {
      eventsManager.unsubscribe(token);
      eventsManager.publish('onWebsocketSubscriptionFailed', { eventName: event });
    }, 10000);
    this.instance.send(JSON.stringify({ action: 'subscribe', message: JSON.stringify([eventId]) }));
  }

  static unsubscribe(event) {
    if (!this.instance || !_commerceId) return;
    this.instance.send(JSON.stringify({ action: 'unsubscribe', message: JSON.stringify([`${event}_${_commerceId}`]) }));
  }

  static close() {
    if (!this.instance || !_commerceId) return;
    this.instance.close(1000, 'Application Ends');
    _commerceId = null;
  }
}
