import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import Dialog from 'Elements/Dialog';
import 'Styles/OrderFailedPage.scss';

function OrderFailedPage({ data }) {
  return (
    <Dialog id="OrderFailedPage" preventClose={true}>
      <div className="order_failed-container">
        <div className="order_failed-fail_icon">
          <Icon icon="healthicons:alert-circle-outline" />
        </div>
        <span className="order_failed-title">¡Oops algo salió mal!</span>
        <span className="order_failed-subtitle">No se pudo generar el enlace de pago</span>
        <button className="order_failed-button" onClick={data.handleTryAgain}>
          <div className="order_failed-bar_button">
            <span>Intentar de nuevo</span>
            <Icon icon="uil:angle-right" />
          </div>
        </button>
        <button className="order_failed-button button_cancel" onClick={data.handleChangeMethod}>
          <div className="order_failed-bar_button">
            <span>Cambiar forma de pago</span>
          </div>
        </button>
        <div className="order_failed-footer">
          <span>
            Si el problema persiste
            <br />
          </span>
          <span>
            repórtalo{' '}
            <a href="" target="_blank" className="order_failed-footer-link">
              AQUí
            </a>
          </span>
        </div>
      </div>
    </Dialog>
  );
}

export default OrderFailedPage;

OrderFailedPage.propTypes = {
  data: PropTypes.exact({
    handleTryAgain: PropTypes.func,
    handleChangeMethod: PropTypes.func,
  }),
};
